/**
 * REACT
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
/**
 * Services
 */
import {
	LoadDataCompany
} from "../../data/services/load-data-service.js";
/**
 * Components
 */
import CustomSpinner from "../common/shared/custom-spinner.js";
import HeadMenu from "../common/headers/head-menu.js";
import CustomFooter from "../common/shared/custom-footer.js";
import OrderItems from "../common/specific/order-items.js";

import { setTheme, getTheme } from "../models/themes.js";

const _orderModel = require("../models/orders.js");
/**
 * Constants
 */
const isBrowser = typeof window !== `undefined`;
//import UseAudio from "../common/shared/common-audio.js";



/**
 * Orders
 * @param {*} _props
 * @returns
 */
const Orders = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [userEmail, setUserEmail] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	// Specific
	setTheme(getTheme());
	/**
	 * Use effect
	 */
	useEffect(() => {
		props = _props.properties;
		// Reload data on loaded state change.

		componentDidMount();
	}, [loaded]);

	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			onError(e);
		}
	}
	/**
	 * Load all datas
	 */
	async function loadAll() {
		_orderModel.emptyOrders();
		var company = global.COMPANY_DATAS;
		if (company == null) {
			company = await LoadDataCompany();
			global.COMPANY_DATAS = company;
			setTheme(company.company.CompanyParams);
			//console.log(data);
			props.pageName = company.companyName;
			props.base64Image = company.companyDetails.CompanyLogo;

		}
		setUserEmail(company.name);
		_logger.DEBUG(
			`Loaded ${props.pageName} with id ${componentId} logged user ${userEmail}`
		);
		document.title = props.pageName;
		console.log(isBrowser);
		// wait for seconds
		setTimeout(() => {
			setLoaded(true);

		}, 50);

	}



	/**
	 *  On error
	 */
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`From Cart ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	/**
	 * Set the component
	 */
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App " key={componentId + "-main"}>
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 bg-primary-gradient",
					textClass: "txt-primary",
					menuButtonClass: "txt-primary m-2",
				}}
			/>
			{/* <PageHeader properties={props} /> */}

			<div
				className="content-container content-margin-top "
				key={componentId}
				style={{ scrollX: "hidden" }}
			>
				<div className="row mt-4"></div>
				<div className="app-side-margin p-0">
					<OrderItems
						properties={{
							pageName: props.pageName,
							trNamespace: props.trNamespace,

						}}
					></OrderItems>
				</div>
				<div className="app-separator-large"></div >

			</div>

			<CustomFooter></CustomFooter>
		</div >
	);
};

Orders.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	backgroundImage: PropTypes.string,
	translatePageTitle: PropTypes.bool,
	showHeaderTooltip: PropTypes.bool,
	base64Image: PropTypes.string
};


export default Orders;
