import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/application/company";

function GetAll() {
	const route = `${baseUrl}/`;
	return route;
}
function GetOne() {
	const route = `${baseUrl}/front-filter`;
	return route;
}
function PostCreate() {
	const route = `${baseUrl}/create`;
	return route;
}
function PutUpdate() {
	const route = `${baseUrl}/update`;
	return route;
}
function PutDelete() {
	const route = `${baseUrl}/delete`;
	return route;
}
function UpdateTime() {
	const route = `${baseUrl}/update-time`;
	return route;
}
function StopDelivery() {
	const route = `${baseUrl}/stop-delivery`;
	return route;
}

export default {
	GetAll,
	GetOne,
	PostCreate,
	PutUpdate,
	PutDelete,
	UpdateTime,
	StopDelivery
};
