/**
 * ! Change OrderManagement by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import CompanyService from "../../../data/services/application/company.js";
import OrderService from "../../../data/services/application/order.js";

const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);

import TipsComponent from "./tips-component.js";

/**
 * Services
 */
import {
	LoadDataCompany
} from "../../../data/services/load-data-service.js";
const _commonOptionsModel = require("../../models/common-options.js");

const OrderManagement = (_props) => {
	var props = _props.properties;
	const componentId = "OrderManagement-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const [userEmail, setUserEmail] = useState(null);
	const [currentDate, setCurrentDate] = useState(new Date().toISOString().split('T')[0]);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	useEffect(() => {
		componentDidMount();

		// Set up an interval to update the date every minute
		const intervalId = setInterval(() => {
			setCurrentDate(new Date().toISOString().split('T')[0]);
		}, 60000); // 60000 milliseconds = 1 minute

		// Clean up the interval when the component unmounts
		return () => clearInterval(intervalId);
	}, []);

	function componentDidMount() {
		try {
			setLoadFunction();
		} catch (e) {
			onError(e);
		}
	}
	// Execute after load until all is ok.
	async function setLoadFunction() {
		try {
			var options = _commonOptionsModel.getCommonOptions();
			var company = global.COMPANY_DATAS;
			if (company == null) {
				company = await LoadDataCompany();
				global.COMPANY_DATAS = company;

				props.pageName = company.companyName;
				props.base64Image = company.companyDetails.CompanyLogo;
			}
			setUserEmail(company.name);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} logged user ${userEmail}`
			);
			document.title = props.pageName;
			setLoadedState(true);
			setTimeout(() => {
				if (options.hideHelp) {
					// click on hide tips switch
					var swtichElement = document.getElementById("hide-tips-switch");
					if (swtichElement != null && !swtichElement.checked) {
						swtichElement.click();
					}
				}
			}, 1000);
		} catch {
			setTimeout(() => {
				setLoadFunction();
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	async function setAllow() {
		// confirm deny orders
		var btn = document.getElementById("btn-stop-orders");
		var CompanyId = global.COMPANY_DATAS.company.Id;
		global.COMPANY_DATAS.company.CompanyParams.CanOrder = true;
		var item = { CanOrder: true, CompanyId: CompanyId };
		await CompanyService.StopDelivery(item, null);
		btn.classList.remove("btn-danger");
		btn.classList.remove("animate-loop");
		btn.classList.remove("animate-heart");
		btn.classList.add("btn-success");
		btn.innerHTML = `<p class="m-auto d-flex align-items-center justify-content-center h2-mob"><i class="fas fa-2x fa-circle-play m-2 mt-0 mb-0"></i><span class=" h2-mob">${" "}${t("orders:start-orders")}</span></p>`;


	}
	async function setDeny() {
		if (window.confirm(t("orders:confirm-stop-orders"))) {
			var btn = document.getElementById("btn-stop-orders");
			var CompanyId = global.COMPANY_DATAS.company.Id;
			global.COMPANY_DATAS.company.CompanyParams.CanOrder = false;
			var item = { CanOrder: false, CompanyId: CompanyId };
			await CompanyService.StopDelivery(item, null);
			btn.classList.remove("btn-success");
			btn.classList.add("btn-danger");
			btn.classList.add("animate-loop");
			btn.classList.add("animate-heart");
			btn.innerHTML = `<p class="m-auto d-flex align-items-center justify-content-center h2-mob">
							<i class="fas fa-2x fa-circle-pause m-2 mt-0 mb-0"></i>
							<span class=" h2-mob">${" "}${t("orders:stop-orders")}</span>
						</p>`;
		}
	}

	async function setDeliveryTime() {
		// Get selected value
		var selected = document.getElementById("delivery-time");
		var DefaultDeliveryTime = selected.options[selected.selectedIndex].value;
		var CompanyId = global.COMPANY_DATAS.company.Id;
		var item = { DefaultDeliveryTime: DefaultDeliveryTime, CompanyId: CompanyId };
		await CompanyService.UpdateTime(item, null);
		var message = t("orders:time-updated.description");
		var severity = "success";
		var title = t("orders:time-updated.title");
		sendToast(message, severity, title);
	}

	async function setPrintZ() {
		//SelectedDate: selectedDate
		// Get now date
		var selectedDate = new Date();
		// if input dateZ has value set selectedDate to input dateZ value
		var dateZ = document.getElementById("dateZ");
		if (dateZ.value != "") {
			selectedDate = new Date(dateZ.value);
		}
		var data = { SelectedDate: selectedDate };
		await OrderService.SetPrintZ(data, null);
		sendToast(t("orders:print-orders.message"), "success", t("orders:print-orders.title"));
	}

	var isWorking = false;

	function hideTips() {

		if (isWorking) return;

		isWorking = true;
		// Get switch hide-tips-switch
		var switchElement = document.getElementById("hide-tips-switch");
		// if switchElement is checked
		if (switchElement.checked) {
			_commonOptionsModel.setCommonOptions({ hideHelp: true });
			// Get all elements with data-type tip-components
			var elements = document.querySelectorAll("[data-type='tip-components']");
			// for each element
			elements.forEach(element => {
				// if element has class show remove
				element.style.display = "none";
			});
		} else {
			_commonOptionsModel.setCommonOptions({ hideHelp: false });
			// Get all elements with data-type tip-components
			elements = document.querySelectorAll("[data-type='tip-components']");
			// for each element
			elements.forEach(element => {
				element.style.display = "block";
			});
		}
		setTimeout(() => {
			isWorking = false;
		}, 200);
	}

	function setTips(name, id) {
		return (
			<TipsComponent
				properties={{
					pageName: props.pageName,
					trNamespace: "orders",
					name: name,
					id: id,
					color: "success"
				}}
			>

			</TipsComponent >)
	}
	function setTimeSlots() {
		//- Set select with each option is 5min from 5 to 120 if option is global.COMPANY_DATAS.company.DefaultDeliveryTime set selected
		var options = [];
		for (var i = 5; i <= 120; i += 5) {

			options.push(<option key={"ts-" + i} value={i}>{i}{" "}{t("orders:min")}</option>);

		}
		var _icon = "fas fa-2x fa-circle-play m-2 mt-0 mb-0";
		var _btnClass = "btn-success";
		var _btnText = t("orders:start-orders");
		var companyParams = global.COMPANY_DATAS.company.CompanyParams;
		if (companyParams != null && !companyParams.CanOrder) {
			_btnClass = "btn-danger animate-loop animate-heart ";
			_btnText = t("orders:stop-orders");
			_icon = "fas fa-2x fa-circle-pause m-2 mt-0 mb-0";
		}
		var btn = (<div className="col-sm-12 col-md-5">
			<button className={`btn ${_btnClass} h-100 w-100 mb-1 mt-1`} id="btn-stop-orders" onClick={async () => {
				// Get btn stop orders and if class is btn-success set class to btn-danger and text to stop orders
				var btn = document.getElementById("btn-stop-orders");
				if (btn.classList.contains("btn-danger")) {
					await setAllow()
				} else {
					await setDeny();
				}
			}}>
				<p className="m-auto d-flex align-items-center justify-content-center h2-mob">
					<i className={_icon}></i>
					<span className=" h2-mob">{" "}{_btnText}</span>
				</p>
			</button>

		</div>);
		return (
			<div className="card m-2 mt-0 mb-4 animate glow">
				<div className="card-header bg-primary-gradient border-rounded" onClick={() => {
					// Get common-options-panel
					var panel = document.getElementById("common-options-panel");
					// if is style.display none set to block
					if (panel.style.display == "none") {
						panel.style.display = "block";

					} else {
						panel.style.display = "none";
					}

				}}>
					<span className="card-title h1-mob">
						<i className="fas fa-cogs"></i>{" "}
						<span>{t("common:options")}</span>
						<i className="fas fa-hand-pointer float-end" id="chevron-option-panel"></i>
					</span>
				</div>
				<div className="card-body" id="common-options-panel" style={{ display: "block" }}>
					<div className="row mt-0 mb-2">
						<div className="col-sm-12 col-md-7 input-group-lg">
							<p className="m-0">
								<span className={`badge text-primary float-start h2-mob`}>
									<span>
										{t("orders:select-z-date")}
									</span>
								</span>
							</p>
							<input 
								type="date" 
								className="form-control mb-2" 
								id="dateZ" 
								value={currentDate} 
								onChange={(e) => setCurrentDate(e.target.value)}
							/>
						</div>
						<div className="col-sm-12 col-md-5">
							<button className="btn btn-secondary w-100 h-100" onClick={() => {
								setPrintZ();
							}}>
								<span className="h1-mob align-content-center">
									<i className="fas fa-square-z align-middle"></i>{" "}
									<span className="align-middle">{" "}{t("orders:print-z")}</span>
								</span>
							</button>
						</div>
					</div>
					<div className="row mb-2" key={componentId}>
						<div className="col-sm-6">
							{setTips("timeslot-manager", 9999)}
						</div>
						<div className="col-sm-6">
							<div className="form-check form-switch form-check-reverse mt-2">
								<label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("common:hide-tooltips")}</label>
								<input className="form-check-input mt-0 mb-0" type="checkbox" role="switch" id="hide-tips-switch" style={{ scale: "1.5", marginLeft: "10px" }} onChange={() => { hideTips() }} />
							</div>
						</div>
					</div>
					<div className="row mb-2" >
						<div className="col-sm-12 col-md-7">
							<p className="m-0">
								<span className={`badge text-primary float-start h2-mob`}>
									<span>
										{t("orders:timeSlots")}
									</span>
								</span>
							</p>
							<div className="input-group input-group-lg  mb-1 mt-1">
								<select className="form-select" id="delivery-time" defaultValue={global.COMPANY_DATAS.company.DefaultDeliveryTime} onChange={async () => {
									setDeliveryTime();
								}}>
									{options}
								</select>
							</div>
						</div>
						{btn}
					</div >

				</div>
			</div >
		)


	}

	return loaded ? (
		setTimeSlots()
	) : null;
};



OrderManagement.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string
};

export default OrderManagement;
