const lsName = "mo_theme";
/**
 * Set theme based on data
 * @param {*} data
 */
function setTheme(data) {
	// check if data has theme prop
	var primary = "#34333d";
	var secondary = "#676675";
	var light = "#f8f9fa";

	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";

	var textDark = "#18171C";
	var textLight = "#F1F1F3";
	var backgroundColor = "#F1F1F3";

	if (data != null && data.Theme != null) {
		if (data.Theme == "ochre") {
			setOchre();
		} else if (data.Theme == "plum") {
			setPlum();
		} else if (data.Theme == "organic") {
			setOrganic();
		} else if (data.Theme == "mineral") {
			setMineral();
		} else if (data.Theme == "slate") {
			setSlate();
		} else if (data.Theme == "blue") {
			setBlue();
		} else if (data.Theme == "red") {
			setRed();
		} else if (data.Theme == "wood") {
			setMaroon();
		} else if (data.Theme == "green") {
			setGreen();
		} else if (data.Theme == "anis") {
			setAnis();
		} else if (data.Theme == "aqua") {
			setAqua();
		} else if (data.Theme == "sky") {
			setSky();
		} else if (data.Theme == "monarch") {
			setMonarch();
		} else if (data.Theme == "candy") {
			setCandy();
		} else if (data.Theme == "pure") {
			setPure();
		} else {
			setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
			setOtherVariables(
				hexToHSL(danger),
				hexToHSL(warning),
				hexToHSL(success),
				hexToHSL(info)
			);
			setTextVariables(
				textLight,
				textLight,
				textLight,
				textLight,
				textLight,
				textLight,
				textDark
			);
			setBackgroundColor(backgroundColor);
		}
		saveTheme(data.Theme);
	}
}
function setOchre() {
	var primary = "#7e684c";
	var secondary = "#b0997c";
	var light = "#ECE7DF";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#201A13";
	var textLight = "#F5F3EF";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#F5F3EF";
	setBackgroundColor(backgroundColor);
}
function setPlum() {
	var primary = "#784949";
	var secondary = "#af7a7a";
	var light = "#F3E4E4";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#201313";
	var textLight = "#F5EFEF";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);

	var backgroundColor = "#F5EFEF";
	setBackgroundColor(backgroundColor);
}
function setOrganic() {
	var primary = "#467454";
	var secondary = "#7aaf8a";
	var light = "#DCEBE0";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#132017";
	var textLight = "#EFF5F1";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#EFF5F1";
	setBackgroundColor(backgroundColor);
}
function setMineral() {
	var primary = "#2c2c2c";
	var secondary = "#6f6f6f";
	var light = "#ddd";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#0D0D0D";
	var textLight = "#F2F2F2";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#A6A6A6";
	setBackgroundColor(backgroundColor);
}
function setSlate() {
	var primary = "#495f78";
	var secondary = "#7a93af";
	var light = "#F1F6F8";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#131920";
	var textLight = "#EFF2F5";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);

	var backgroundColor = "#EFF2F5";
	setBackgroundColor(backgroundColor);
}
function setBlue() {
	var primary = "#35456B";
	var secondary = "#C3CDEA";
	var light = "#EDEFF5";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#111622";
	var textLight = "#EEF1F7";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);

	var backgroundColor = "#EEF1F7";
	setBackgroundColor(backgroundColor);
}
function setRed() {
	var primary = "#8e2623";
	var secondary = "#f27777";
	var light = "#f6e7d8";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#290B0A";
	var textLight = "#FAEBEB";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#FAEBEB";
	setBackgroundColor(backgroundColor);
}
function setMaroon() {
	var primary = "#644939";
	var secondary = "#9D8271";
	var light = "#F6EDE8";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#F4BF01";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#201813";
	var textLight = "#F6F1EF";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#F6F1EF";
	setBackgroundColor(backgroundColor);
}
function setGreen() {
	var primary = "#28463c";
	var secondary = "#688f4e";
	var light = "#E5F3E6";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FFBE5B";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#09100E";
	var textLight = "#EFF6F3";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#EFF6F3";
	setBackgroundColor(backgroundColor);
}
function setAnis() {
	var primary = "#94B447";
	var secondary = "#BBCC66";
	var light = "#F8FCEE";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#DE8230";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#2D3716";
	var textLight = "#F4F8ED";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textDark,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#F4F8ED";
	setBackgroundColor(backgroundColor);
}
function setAqua() {
	var primary = "#367bc3";
	var secondary = "#B8E9E0";
	var light = "#E6F9F4";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#10263C";
	var textLight = "#EBF2FA";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#EBF2FA";
	setBackgroundColor(backgroundColor);
}
function setSky() {
	var primary = "#2d82b5";
	var secondary = "#88cdf6";
	var light = "#F0FAFC";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FFDE59";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#0F2C3D";
	var textLight = "#EBF4FA";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textDark,
		textDark,
		textLight,
		textLight,
		textDark,
		textLight,
		textDark
	);
	var backgroundColor = "#EBF4FA";
	setBackgroundColor(backgroundColor);
}
function setMonarch() {
	var primary = "#725a7a";
	var secondary = "#bfb8da";
	var light = "#E7DFE9";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#29212C";
	var textLight = "#F3F0F4";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textLight,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#F3F0F4";
	setBackgroundColor(backgroundColor);
}
function setCandy() {
	var primary = "#e79796";
	var secondary = "#ffc98b";
	var light = "#FDF0F5";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#46995A";
	var info = "#449FAD";
	var textDark = "#3E0F0E";
	var textLight = "#FAEAEA";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textDark,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);
	var backgroundColor = "#FAEAEA";
	setBackgroundColor(backgroundColor);
}
function setPure() {
	var primary = "#6c8a9b";
	var secondary = "#e6e6e4";
	var light = "#FBFAFA";
	setVariables(hexToHSL(primary), hexToHSL(secondary), hexToHSL(light));
	var danger = "#DC4F4F";
	var warning = "#FF8809";
	var success = "#42A040";
	var info = "#5DBDE9";
	var textDark = "#1F282E";
	var textLight = "#F0F3F5";
	setOtherVariables(
		hexToHSL(danger),
		hexToHSL(warning),
		hexToHSL(success),
		hexToHSL(info)
	);
	setTextVariables(
		textDark,
		textDark,
		textLight,
		textLight,
		textLight,
		textLight,
		textDark
	);

	var backgroundColor = "#F0F3F5";
	setBackgroundColor(backgroundColor);
}
/**
 * Set background
 * @param {*} backgroundColor
 */
function setBackgroundColor(backgroundColor) {
	var root = document.documentElement;
	root.style.setProperty("--background", backgroundColor);
}
/**
 * Set variables
 * @param {*} primary
 * @param {*} secondary
 * @param {*} light
 */
function setVariables(primary, secondary, light) {
	var root = document.documentElement;
	root.style.setProperty("--primary-h", primary[0]);
	root.style.setProperty("--primary-c", primary[1] + "%");
	root.style.setProperty("--primary-l", primary[2] + "%");
	root.style.setProperty("--secondary-h", secondary[0]);
	root.style.setProperty("--secondary-c", secondary[1] + "%");
	root.style.setProperty("--secondary-l", secondary[2] + "%");
	root.style.setProperty("--light-h", light[0]);
	root.style.setProperty("--light-c", light[1] + "%");
	root.style.setProperty("--light-l", light[2] + "%");
}
/**
 * Set other variables
 * @param {*} danger
 * @param {*} warning
 * @param {*} success
 * @param {*} info
 */
function setOtherVariables(danger, warning, success, info) {
	var root = document.documentElement;
	root.style.setProperty("--success-h", success[0]);
	root.style.setProperty("--success-c", success[1] + "%");
	root.style.setProperty("--success-l", success[2] + "%");
	root.style.setProperty("--warning-h", warning[0]);
	root.style.setProperty("--warning-c", warning[1] + "%");
	root.style.setProperty("--warning-l", warning[2] + "%");
	root.style.setProperty("--danger-h", danger[0]);
	root.style.setProperty("--danger-c", danger[1] + "%");
	root.style.setProperty("--danger-l", danger[2] + "%");
	root.style.setProperty("--info-h", info[0]);
	root.style.setProperty("--info-c", info[1] + "%");
	root.style.setProperty("--info-l", info[2] + "%");
}
/**
 * Set text variables
 * @param {*} primary
 * @param {*} secondary
 * @param {*} info
 * @param {*} success
 * @param {*} warning
 * @param {*} danger
 * @param {*} dark
 */
function setTextVariables(
	primary,
	secondary,
	info,
	success,
	warning,
	danger,
	dark
) {
	var root = document.documentElement;
	root.style.setProperty("--txt-primary", primary);
	root.style.setProperty("--txt-secondary", secondary);
	root.style.setProperty("--txt-info", info);
	root.style.setProperty("--txt-success", success);
	root.style.setProperty("--txt-warning", warning);
	root.style.setProperty("--txt-danger", danger);
	root.style.setProperty("--txt-dark", dark);
}
/**
 * Convert hex to HSL
 * @param {*} hexColor
 * @returns
 */
function hexToHSL(hexColor) {
	return hexToRgb(hexColor);
}
/**
 * Convert hex to RGB
 * @param {*} hexColor
 * @returns
 */
function hexToRgb(hexColor) {
	let r = 0,
		g = 0,
		b = 0;

	// 3 digits
	if (hexColor.length == 4) {
		r = "0x" + hexColor[1] + hexColor[1];
		g = "0x" + hexColor[2] + hexColor[2];
		b = "0x" + hexColor[3] + hexColor[3];

		// 6 digits
	} else if (hexColor.length == 7) {
		r = "0x" + hexColor[1] + hexColor[2];
		g = "0x" + hexColor[3] + hexColor[4];
		b = "0x" + hexColor[5] + hexColor[6];
	}

	return rgbToHsl(r, g, b);
}
/**
 * Convert RGB to HSL
 * @param {*} r
 * @param {*} g
 * @param {*} b
 * @returns
 */
function rgbToHsl(r, g, b) {
	// Convert r, g, b [0, 255] range to [0, 1]
	r = r / 255;
	g = g / 255;
	b = b / 255;

	// Get the min and max of r, g, b
	const max = Math.max(r, g, b);
	const min = Math.min(r, g, b);

	// Calculate lightness (average of largest and smallest color components)
	var lum = (max + min) / 2;

	let hue, sat;

	if (max === min) {
		// No saturation
		hue = 0;
		sat = 0;
	} else {
		const c = max - min; // Chroma
		// Saturation is simply the chroma scaled to fill the interval [0, 1]
		sat = c / (1 - Math.abs(2 * lum - 1));

		switch (max) {
			case r:
				hue = (g - b) / c;
				break;
			case g:
				hue = (b - r) / c + 2;
				break;
			case b:
				hue = (r - g) / c + 4;
				break;
		}
	}

	// Convert hue to degrees and round
	hue = Math.round(hue * 60);
	sat = Math.round(sat * 100);
	lum = Math.round(lum * 100);

	return [hue, sat, lum];
}
/**
 * Save theme to local storage
 * @param {*} theme
 */
function saveTheme(theme) {
	localStorage.setItem(lsName, theme);
}
/**
 * Get theme from local storage
 * @returns
 */
function getTheme() {
	return localStorage.getItem(lsName);
}
module.exports = { setTheme, saveTheme, getTheme };
