/**
 * ! Change CategoryCard by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";

const CategoryCard = (_props) => {
	var props = _props.properties;
	const componentId = "CategoryCard-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	componentDidMount();

	function componentDidMount() {
		try {
			//console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}

	function onError(error) {
		handleShowToast("error", t("error", { ns: props.trNamespace }), `Category Card ${error}`);
	}

	function setCategoryContainer() {
		var img = null;
		var imgClass = "left-inline-card-img";

		var description = null;
		if (
			props.category.CategoryDescription != null &&
			props.category.CategoryDescription != ""
		) {
			description = (
				<div className="card-body m-0 p-0 bg-transparent">
					<div className="container-inline-right m-0 p-0">
						<p className=" m-2 mb-0 mt-0 p-0 h2-mob">
							{props.category.CategoryDescription}
						</p>
					</div>
				</div>
			);
			imgClass = "top-left-inline-card-img";
		}
		if (
			props.category.CategoryImage != null &&
			props.category.CategoryImage != "" &&
			props.category.CategoryImage.toLowerCase() != "null" &&
			!props.category.CategoryImage.startsWith("http")
		) {
			img = (
				<img
					alt={props.category.CategoryName}
					src={`data:image/png;base64,${props.category.CategoryImage}`}
					className={`img-sm ${imgClass} margin-left-xxs m-0 mb-0 mt-0`}
				/>
			);
		}
		return (
			<div
				className={`card ${props.additionalCardClassName ?? "bg-primary-gradient"
					} card-no-padding-margin animate blur delay-${props.count} w-100`}
				style={{ minHeight: "100px" }}

				key={componentId}
			>
				<div className="card-header bg-transparent card-no-padding-margin   m-0 mb-0 mt-0 p-0 ">
					<div className="container-inline m-0 mb-0 mt-0 p-0">
						{img}
						<div className="text-start m-2 mb-0 mt-0 w-100 ">
							<p className="mt-2 h0-mob font-bold">{props.category.CategoryName}</p>

						</div>
					</div>
				</div>
				{description}
				<div className="card-footer p-0 m-0 pt-3">
					<div className="row m-2 mt-0 mb-0">
						<div className="col-6">
							<p className="float-start h1-mob mb-2">
								<span className="d-flex align-items-start opacity-mid m-2 mt-0 mb-0">
									<span className=" badge bg-white " style={{ maxWidth: "100px", textWrap: "wrap" }}>
										<span className="text-info">{t("stock", { ns: props.trNamespace })}</span>
									</span>
									<span>
										<i className="fas text-white fa-caret-right animate-loop animate-left"></i>
									</span>
								</span>
							</p>
						</div>
						<div className="col-6">
							<p className="float-end h1-mob mb-2">
								<span className="d-flex align-items-end opacity-mid m-0">
									<span>
										<i className="fas text-white fa-caret-left animate-loop animate-right"></i>
									</span>
									<span className=" badge bg-white end-0" style={{ maxWidth: "100px", textWrap: "wrap" }}>
										<span className="text-danger">{t("out-of-stock", { ns: props.trNamespace })}</span>
									</span>
								</span>
							</p>
						</div>
					</div>
				</div>

			</div >
		);
	}

	return setCategoryContainer();
};

CategoryCard.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	category: PropTypes.object,
	count: PropTypes.number,
	additionalCardClassName: PropTypes.string
};

export default CategoryCard;
