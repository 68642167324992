import { useEffect } from "react";
import CustomSpinner from "./components/common/shared/custom-spinner.js";
import { setTheme, getTheme } from "./components/models/themes.js";
import { initTranslations } from "./i18n/i18n.js";
import CustomFooter from "./components/common/shared/custom-footer.js";

function App() {
	const { t } = initTranslations();

	setTheme(getTheme());

	useEffect(() => {
		document.title = t("index:App.title");
		if (process.env.REACT_APP_COMPANY_ID != null) {
			global.cpname = process.env.REACT_APP_SITE_TITLE;
			global.cplogo = process.env.REACT_APP_CIRCLE_LOGO;
		}
	}, []);


	return (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: global.cpname,
						trNamespace: "common",
						showSpinner: false
					}}
				/>
				<div className="m-4"></div>
				<h2 className="animate blur  delay-1">
					{process.env.REACT_APP_COMPANY_ID
						? process.env.REACT_APP_SITE_TITLE ?? global.cpname
						: global.cpname}
				</h2>
				<button onClick={()=>{
					window.location.href = "/dashboard";
				}} className="btn btn-warning">
					{t("common:start-day")}
				</button>
			</header>
			<CustomFooter></CustomFooter>
		</div>
	);
}

export default App;
