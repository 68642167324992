/**
 * @file Toast Context
 * @module ToastContext
 * @description Provides a context for displaying toast notifications.
 */

import PropTypes from "prop-types";
import { createContext, useContext, useRef } from "react";
//import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import { toast } from 'react-toastify';
import useSound from 'use-sound';

import SoundLib from "../data/routes/application/sounds.js";

//import "primereact/resources/themes/lara-light-cyan/theme.css";

/**
 * Context for displaying toast notifications.
 * @typedef {Object} ToastContext
 * @property {function} handleShowToast - Function to show a toast notification.
 */

const ToastContext = createContext(undefined);

/**
 * Provides a context for displaying toast notifications.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
export const ToastContextProvider = ({ children }) => {
	const toastRef = useRef(null);
	const [playSound] = useSound(SoundLib.GetNotifySound(process.env.REACT_APP_SOUND));
	/**
	 * Shows a toast notification.
	 * @param {Object} options - The options for the toast notification.
	 * @param {string} options.severity - The severity of the toast notification.
	 * @param {string} options.summary - The summary of the toast notification.
	 * @param {string} options.detail - The detail of the toast notification.
	 */

	const showToast = (options) => {
		var toastContent = {
			position: "bottom-left",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "colored"
		}
		if (options.severity == "success") {
			toast.success(options.detail, toastContent);
		} else if (options.severity == "error") {
			toast.error(options.detail, toastContent);
		} else if (options.severity == "warning") {
			toast.warning(options.detail, toastContent);
		} else if (options.severity == "info") {
			toast.info(options.detail, toastContent);
		} else {
			toastContent.theme = "light";
			toast(options.detail, toastContent);
		}
		if (options.playSound) {
			// get element sound-btn
			playSound();
		}
	};


	/**
	 * Handles showing a toast notification.
	 * @param {string} type - The type of the toast notification.
	 * @param {string} title - The title of the toast notification.
	 * @param {string} message - The message of the toast notification.
	 */
	const handleShowToast = (type, title, message, playSound = false) => {
		showToast({
			severity: type,
			summary: title,
			detail: message,
			playSound: playSound
		});
	};

	return (
		<ToastContext.Provider value={{ handleShowToast }}>
			<div
				className="toast-container position-fixed bottom-0 end-0 p-3"
				style={{ zIndex: "3000" }}
			>
				<div
					className="toast"
					role="alert"
					aria-live="assertive"
					aria-atomic="true"
					ref={toastRef}
					id="toast-ref"
				>
					<div className="toast-header toast-mob" id="toast-header">
						<div id="toast-icon"></div>
						<strong className="me-auto toast-mob" id="toast-summary"></strong>
						<button
							type="button"
							className="btn-close toast-mob"
							data-bs-dismiss="toast"
							aria-label="Close"
						></button>
					</div>
					<div className="toast-body toast-mob" id="toast-message">
						Hello, world! This is a toast message.
					</div>
				</div>
			</div>
			{children}
		</ToastContext.Provider>
	);
};

ToastContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

/**
 * Custom hook to access the ToastContext.
 * @returns {ToastContext} The ToastContext object.
 * @throws {Error} Throws an error if used outside of ToastContextProvider.
 */
export const useToastContext = () => {
	const context = useContext(ToastContext);
	if (!context) {
		throw new Error("useToastContext must be used within ToastContextProvider");
	}
	return context;
};
