/**
 * REACT
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import SwipeItem from "./swipe-item.js";
import OrderThumbnail from "./order-thumbnail.js";
import OrderService from "../../../data/services/application/order.js";
import PrintComponent from "./print-component.js";

const OrderItemsContent = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [refresh, setRefresh] = useState(process.env.REACT_APP_ORDER_REFRESH);
	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	const minOrderStatus = 1;
	const maxOrderStatus = 3;

	const [previousStep, setPreviousStep] = useState(null);
	const [nextStep, setNextStep] = useState(null);

	async function componentDidMount() {
		try {
			setLoadFunction();
		} catch (e) {
			onError(e);
		}
	}
	/**
	  * Load all datas
	  */
	async function setLoadFunction() {
		setRefresh(process.env.REACT_APP_ORDER_REFRESH ?? 10000);
		var next = props.order.OrderStatus + 1;
		var previous = props.order.OrderStatus - 1;
		if (previous >= minOrderStatus) {
			setPreviousStep(t("orders:setStatus") + "\n\"" + t("order-status-" + previous + "-single", { ns: props.trNamespace }) + "\"");
		}
		if (next <= maxOrderStatus) {
			setNextStep(t("orders:setStatus") + "\n\"" + t("order-status-" + next + "-single", { ns: props.trNamespace }) + "\"");
		}
		// wait for seconds
		setTimeout(async () => {
			setLoaded(true);
			await TickGetDatas()
			ordersRefresh();
		}, 50);

	}
	/**
	  * Handles errors and shows a toast message.
	  * @param {Error} error - The error object.
	  */
	function onError(error) {
		sendToast(
			`From Cart ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	async function ordersRefresh() {
		setTimeout(async () => {
			await TickGetDatas();
		}, refresh);
	}
	async function TickGetDatas() {
		setTimeAnimation();
	}

	function setTimeAnimation() {
		var order = props.order;
		var orderDate = new Date(order.DeliveryDate);
		// Order date is in actual timezone, convert it to utc
		orderDate = new Date(orderDate.getTime() + orderDate.getTimezoneOffset() * 60000);
		var now = new Date()
		// now utc using actual timezone
		now = new Date(now.getTime());
		var _class = ["bg-success"];
		if (orderDate < now) {
			_class = ["bg-danger", "animate-loop", "animate-heart"];
		}
		else if (orderDate < new Date(now.getTime() + 15 * 60000)) {
			_class = ["bg-warning", "animate-loop", "animate-heart"];
		}
		// get element `badge-${componentId}-${props.order.Id}`
		var element = document.getElementById(`badge-order-${props.order.Id}`);
		if (element != null) {
			// if element has _class skip
			if (element.classList.contains(_class[0])) {
				return;
			}
			// remove all classes
			element.classList.remove("bg-success");
			element.classList.remove("bg-danger");
			element.classList.remove("bg-warning");
			element.classList.remove("animate-loop");
			element.classList.remove("animate-heart");

			// add class to element
			element.classList.add(_class);
		}
	}

	function setChildren(order) {
		var date = order.DeliveryDate;
		// format date to date using actual culture
		var dateStr = new Date(date).toLocaleString().split(" ")[0];
		var hour = date.split("T")[1].split(":")[0] + ":" + date.split("T")[1].split(":")[1];
		// Check if hour is < than now
		var now = new Date()
		// now utc using actual timezone
		now = new Date(now.getTime());
		// set time with utc date time
		var orderDate = new Date(order.DeliveryDate);
		// Order date is in actual timezone, convert it to utc
		orderDate = new Date(orderDate.getTime() + orderDate.getTimezoneOffset() * 60000);
		var _class = "badge bg-success";
		if (orderDate < now) {
			_class = "badge bg-danger animate-loop animate-heart";
		}
		else if (orderDate < new Date(now.getTime() + 15 * 60000)) {
			_class = "badge bg-warning animate-loop animate-heart";
		}
		var user = order.UserInfos;

		var categoryProducts = [];
		var totalProducts = 0;
		for (var i = 0; i < order.OrderDetails.length; i++) {
			if (!categoryProducts.includes(order.OrderDetails[i])) {
				categoryProducts.push(order.OrderDetails[i]);
			}
			totalProducts += parseInt(order.OrderDetails[i].TotalQty.toString());
		}
		var card = (

			<div className="card w-100 " key={order.Id}>
				<div className="card-header bg-primary-gradient pt-4 pb-4"
					onClick={() => {
						// get order-detail-Id
						var id = `order-detail-${order.Id}`;
						var element = document.getElementById(id);
						if (element.style.display === "none") {
							element.style.display = "block";
						} else {
							element.style.display = "none";
						}
					}}
				>
					<div className="row">
						<div className="col-12">
							<p className="mt-0 mb-0 float-start h1-mob">
								<span>{t("orderNumber", { ns: props.trNamespace })}{" "}{order.Id}</span>
							</p>
							<p className="mt-0 mb-0 float-end h1-mob">
								<span className={`badge ${_class} float-end`} id={`badge-order-${props.order.Id}`}>{dateStr}{" "}{hour}</span>
							</p>
						</div>

						<div className="col-12 ">
							<p className="w-100 h2-mob mt-2">
								<span className="float-start">{t("common:for")}{" "}{user.firstName}{" "}{user.lastName}</span>
								<span className="float-end" style={{ marginLeft: "10px" }}>{t("products:products")}{" "}{totalProducts}</span>
							</p>
						</div>
						<div className="col-12">
							<hr></hr>
							{setSliders(order)}
						</div>
					</div>
				</div>
				<div className="card-body animate pop" id={`order-detail-${order.Id}`} style={{ display: "none" }}>


					<div className="row">
						<div className="col-sm-12 text-start">
							<PrintComponent
								properties={{
									order: props.order,
									trNamespace: props.trNamespace
								}}
							></PrintComponent>
						</div>
						{order.OrderComment != null && order.OrderComment != "" ?
							(<div className="col-12 text-start">
								<div className=" card text-center m-1 animate blur">

									<div className="card-body border-radius">
										{order.OrderComment}
									</div>
								</div>

							</div>) : null
						}
						{categoryProducts.map((item, index) => {
							index++;
							return (
								<OrderThumbnail
									key={`oc-` + index}
									properties={{
										item: item,
										trNamespace: props.trNamespace,
										count: index
									}}
								></OrderThumbnail>
							);
						})}

					</div>
				</div>
			</div >


		);
		return card;

	}
	function setItems() {
		var order = props.order;
		var leftC = "btn-primary";
		var rightC = "btn-primary";
		if (order.OrderStatus > minOrderStatus) {
			leftC = "btn-warning";
		}
		if (order.OrderStatus < maxOrderStatus) {
			rightC = "btn-success";
		}
		var status = order.OrderStatus;
		var obj = (
			<div className="col-12 mb-4" key={order.Id} id={componentId}>
				<SwipeItem
					id={"swipe-" + order.Id}
					properties={{
						item: order,
						child: setChildren(order),
						itemName: order.Id,
						trNamespace: props.trNamespace,
						fullLeftAction: () => { order.OrderStatus < maxOrderStatus ? NextStep(status) : null },
						fullLeftActionName: nextStep,
						fullRightAction: () => { order.OrderStatus > minOrderStatus ? PreviousStep(status) : null },
						fullRightActionName: previousStep,
						fullLeftColorClass: rightC,
						fullRightColorClass: leftC,
					}}
				/></div>
		);

		return obj;
	}

	function setSliders(order) {

		var previous = null;
		var next = null;
		if (order.OrderStatus > minOrderStatus) {
			previous = (
				<p className="float-start h2-mob" onClick={() => {
					PreviousStep(order.OrderStatus);
				}}>
					<span className="d-flex align-items-center m-2 mt-0 mb-0">
						<span className="h-100">
							<i className="fas text-white fa-caret-left animate-loop animate-right"></i>
						</span>
						<span className=" badge bg-white " style={{ maxWidth: "200px", textWrap: "wrap" }}>
							<span className="text-info">{previousStep}</span>
						</span>

					</span>
				</p>
			)
		}
		if (order.OrderStatus < maxOrderStatus) {
			next = (<p className="float-end h2-mob" onClick={() => {
				NextStep(order.OrderStatus);
			}}>
				<span className="d-flex align-items-center m-2 mt-0 mb-0">
					<span className=" badge bg-white end-0" style={{ maxWidth: "200px", textWrap: "wrap" }}>
						<span className="text-danger">{nextStep}</span>
					</span>
					<span className="h-100">
						<i className="fas text-white fa-caret-right animate-loop animate-left"></i>
					</span>
				</span>
			</p>)
		} else {
			next = (<p className="float-end h1-mob" onClick={() => {
				CloseOrder();
			}}>
				<span className="d-flex align-items-center m-2 mt-0 mb-0">
					<span className=" badge bg-warning end-0" style={{ maxWidth: "200px", textWrap: "wrap" }}>
						<i className="fas fa-triangle-exclamation" style={{ marginRight: "10px" }}></i>
						{t("orders:close-order")}
					</span>
				</span>
			</p>

			)
		}

		return (
			<div className="row m-2 mt-0 mb-0">
				<div className="col-6">
					{previous}
				</div>
				<div className="col-6">
					{next}
				</div>
			</div>

		)
	}

	function PreviousStep(status) {

		if (props.order.OrderStatus == status - 1) return;
		if (status > minOrderStatus) {
			props.order.OrderStatus = status - 1;
			if (props.order.OrderStatus < minOrderStatus) props.order.OrderStatus = minOrderStatus;
			OrderService.SetStatus(props.order, null);
			// Delete this component from the DOM
			SetNewParent(status - 1);
		}
	}
	function NextStep(status) {
		if (props.order.OrderStatus == status + 1) return;
		if (status < maxOrderStatus) {
			props.order.OrderStatus = status + 1;
			if (props.order.OrderStatus > maxOrderStatus) props.order.OrderStatus = maxOrderStatus;
			OrderService.SetStatus(props.order, null);
			// Delete this component from the DOM
			SetNewParent(status + 1);
		}
	}
	function CloseOrder() {
		// confirm close order
		if (window.confirm(t("orders:confirm-close-order"))) {
			// set order status to maxOrderStatus + 1
			if (props.order.OrderStatus == maxOrderStatus + 1) return;
			props.order.OrderStatus = maxOrderStatus + 1;
			OrderService.SetStatus(props.order, null);
			SetNewParent(maxOrderStatus + 1);
		}

	}
	function SetNewParent(status) {
		props.itemChange(componentId, status, props.order);
	}

	return loaded ?


		setItems()

		: null;
};

OrderItemsContent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	itemChange: PropTypes.func,
	order: PropTypes.object,
};

export default OrderItemsContent;
