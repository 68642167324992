/**
 * ! Change UserProfile by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import CustomFooter from "../shared/custom-footer.js";
import userService from "../../../data/services/application/user-details.js";
import loginService from "../../../data/services/application/login.js";

const _userModel = require("../../models/user.js");

const UserProfile = (_props) => {
	//! Properties
	var props = _props.properties;
	const componentId = "UserProfile-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);
	const [userDetails, setUserDetails] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	//! Init
	useEffect(() => {
		componentDidMount();
	}, [loaded]);
	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			setLoadFunction();
		} catch (e) {
			onError(e);
		}
	}
	/**
	 * Sets the load function
	 */
	async function setLoadFunction() {
		if (loaded) return;
		setTimeout(async () => {
			var user = await userService.GetAll();
			setUserDetails(user[0]);
			setLoadedState(true);
			setTimeout(() => {
				setFormFields(user[0]);
			}, 1000);
		}, 500);
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(
			`User Profile Page ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}
	//! Functions
	/**
	 * Sets form fields
	 * @param {*} user
	 */
	function setFormFields(user) {
		// set user object to inputs
		if (user == null) return;
		document.getElementById("up_iimageTxt").value = user.UserAvatar;
		document.getElementById("up_username").value = user.DisplayName;
		document.getElementById("up_firstname").value = user.FirstName;
		document.getElementById("up_lastname").value = user.LastName;
		document.getElementById("up_email").value = global.LOGGED_USER.Email;
		document.getElementById("up_phone").value = user.PhoneNumber;

		var date = new Date().toLocaleDateString();
		if (user.BirthDate != null) {
			date = new Date(user.BirthDate).toLocaleDateString();
		}
		// set date for input date yyyy-mm-dd
		date = date.split("/").reverse().join("-");
		document.getElementById("up_birthDate").value = date;
		// add event listener to inputs except up_email
		document
			.getElementById("up_username")
			.addEventListener("input", fieldChanged);
		document
			.getElementById("up_firstname")
			.addEventListener("input", fieldChanged);
		document
			.getElementById("up_lastname")
			.addEventListener("input", fieldChanged);
		document.getElementById("up_phone").addEventListener("input", fieldChanged);
		document
			.getElementById("up_birthDate")
			.addEventListener("input", fieldChanged);
	}
	/**
	 * Field changed event
	 */
	function fieldChanged() {
		// if button save does not exist create it and add it to the footer
		var btn = document.getElementById("save-btn");
		if (btn == null) {
			btn = document.createElement("button");
			btn.id = "save-btn";
			btn.className = "btn btn-success";
			btn.innerHTML = `<i class="fas fa-save"></i>&nbsp;<span class="m-2 mb-0 mt-0">${t(
				"save",
				{
					ns: "common"
				}
			)}</span>`;
			btn.onclick = saveUser;
			document.getElementById("up-content-footer").appendChild(btn);
		}
	}
	/**
	 * Save user
	 */
	async function saveUser() {
		// Check if email is ok
		/*
	"incorrect-phone": "Incorrect phone number",
	"incorrect-email": "Incorrect email",
	"incorrect-displayname": "Incorrect display name",
	"incorrect-firstname": "Incorrect first name",
	"incorrect-lastname": "Incorrect last name"


*/
		if (!_userModel.checkEmail(document.getElementById("up_email").value)) {
			//toast
			sendToast(t("login:incorrect-email"), "error", t("common:error"));
			return;
		}
		if (!_userModel.checkPhone(document.getElementById("up_phone").value)) {
			//toast
			sendToast(t("login:incorrect-phone"), "error", t("common:error"));
			return;
		}
		if (
			document.getElementById("up_username").value == null ||
			document.getElementById("up_username").value == ""
		) {
			sendToast(t("login:incorrect-displayname"), "error", t("common:error"));
			return;
		}
		if (
			document.getElementById("up_firstname").value == null ||
			document.getElementById("up_firstname").value == ""
		) {
			sendToast(t("login:incorrect-firstname"), "error", t("common:error"));
			return;
		}
		if (
			document.getElementById("up_lastname").value == null ||
			document.getElementById("up_lastname").value == ""
		) {
			sendToast(t("login:incorrect-lastname"), "error", t("common:error"));
			return;
		}
		// Check if user wants to save changes
		if (!window.confirm(t("save-changes-confirm", { ns: "common" }))) return;
		var __user = await userService.GetAll();
		if (__user != null && __user.length > 0) {
			__user = __user[0];
		}
		var img = __user.UserAvatar;
		if (
			document.getElementById("up_iimageTxt").value != null &&
			document.getElementById("up_iimageTxt").value != ""
		) {
			img = document.getElementById("up_iimageTxt").value;
		}

		// Get fields
		var user = {
			Id: __user.Id,
			DisplayName: document.getElementById("up_username").value,
			FirstName: document.getElementById("up_firstname").value,
			LastName: document.getElementById("up_lastname").value,
			PhoneNumber: document.getElementById("up_phone").value,
			BirthDate: document.getElementById("up_birthDate").value,
			UserAvatar: img,
			UserId: __user.UserId
		};
		// Save user
		var res = await userService.UpdateOne(user);
		if (res.affectedRows > 0) {
			sendToast(
				t("save-user-success", { ns: "common" }),
				"success",
				t("common:success")
			);
		} else {
			sendToast(
				t("save-user-error", { ns: "common" }),
				"error",
				t("common:error")
			);
		}
	}
	/**
	 * Sets user image
	 * @param {*} _class
	 * @returns
	 */
	function setCardImage(_class) {
		//console.log("setProductImage()");
		if (_class == null) _class = "";
		var img = (
			<img
				className={_class}
				src={require("../../../rits-package/img/icons/small/noImg.png")}
				id="up_iimageImg"
			/>
		);
		if (userDetails.UserAvatar != null && userDetails.UserAvatar != "") {
			if (userDetails.UserAvatar.startsWith("http")) {
				img = (
					<img
						alt={props.pageName}
						src={`url:${userDetails.UserAvatar}`}
						className={_class}
						id="up_iimageImg"
					/>
				);
			} else {
				img = (
					<img
						alt={props.pageName}
						src={`data:image/png;base64,${userDetails.UserAvatar}`}
						className={_class}
						id="up_iimageImg"
					/>
				);
			}
		}
		return (
			<>
				<div>
					{img}
					<input
						className="form-control mt-2"
						type="file"
						id="up_iimage"
						onChange={() => {
							// Get file from input iimage
							var file = document.getElementById("up_iimage").files[0];
							// Convert file to b64
							var reader = new FileReader();
							var b64 = "";
							reader.onloadend = function () {
								document.getElementById("up_iimageImg").src = reader.result;

								b64 = reader.result;
								// remove data:image/webp;base64, from b64
								b64 = b64.replace(/^data:image\/[a-z]+;base64,/, "");
								document.getElementById("up_iimageTxt").value = b64;
							};
							reader.readAsDataURL(file);
							fieldChanged();
						}}
					/>
					<input type="text" id="up_iimageTxt" style={{ display: "none" }} />
				</div>
			</>
		);
	}
	/**
	 * Sets the card header
	 * @returns
	 */
	function setCardHeader() {
		return (
			<>
				<div className="row p-2">
					<div className="container-inline-center">
						{setCardImage("img-lg-responsive-logo border-rounded")}
					</div>
				</div>
				<hr></hr>
				<div className="row p-2">
					<div className="col-xs-12">
						<label
							htmlFor="username"
							className="form-label float-start badge txt-primary"
						>
							{t("displayname.label", { ns: "login" })}
						</label>
						<input type="text" className="form-control" id="up_username" />
					</div>
				</div>
			</>
		);
	}
	/**
	 * Sets the card
	 * @returns
	 */
	function setCardFooter() {
		return (
			<div className="container-inline-spaced" id="up-content-footer">
				<button
					className="btn btn-danger"
					onClick={forgotPassword}
					id="forgot-password"
				>
					<i className="fas fa-key"></i>&nbsp;
					<span className="m-2 mb-0 mt-0">
						{t("password-reset.label", { ns: "login" })}
					</span>
				</button>
			</div>
		);
	}
	/**
	 * Sets the card
	 * @returns
	 */
	function setCard() {
		return (
			<div
				className="col-xs-12 col-md-6 col-lg-4 p-2 animate blur"
				key={userDetails.Id}
			>
				<div className="card h-100">
					<div className="card-header bg-primary-gradient p-4">
						{setCardHeader()}
					</div>
					<div className="card-body bg-secondary w-100">
						{setCardBody()}
						<div className="m-4"></div>
					</div>
					<div className="card-footer bg-primary-gradient">
						{setCardFooter()}
					</div>
				</div>
			</div>
		);
	}
	/**
	 * Sets the card body
	 * @returns
	 */
	function setCardBody() {
		return <center className="w-100">{setDetails()}</center>;
	}
	/**
	 * Forgot password
	 * @returns
	 */
	async function forgotPassword() {
		// get el username value
		var username = document.getElementById("up_email").value;
		if (username == null || username == "") {
			sendToast("Login.SetUsername", "error", "Login");
			return;
		}
		if (!_userModel.checkEmail(username)) {
			return;
		}
		var data = {
			username: username
		};
		var res = await loginService.ResetPassword(data);

		if (res != null && res.success == true) {
			sendToast(
				t("login:password-reset.email-sent"),
				"success",
				t("common:success")
			);
		} else {
			sendToast(
				t("login:password-reset.email-not-sent"),
				"error",
				t("common:error")
			);
		}
		// disable button and remove click event
		document.getElementById("forgot-password").disabled = true;
		document.getElementById("forgot-password").onclick = null;
	}
	/**
	 * Sets the user details
	 * @returns
	 */
	function setDetails() {
		//console.log(userDetails);
		// Set User birthDate UTC to string

		return (
			<div className="w-100">
				<div className="row m-2">
					<div className="col-xs-12 col-md-6">
						<label
							htmlFor="username"
							className="form-label float-start badge txt-dark"
						>
							{t("firstname.label", { ns: "login" })}
						</label>
						<input type="text" className="form-control" id="up_firstname" />
					</div>
					<div className="col-xs-12 col-md-6">
						<label
							htmlFor="username"
							className="form-label float-start badge txt-dark"
						>
							{t("lastname.label", { ns: "login" })}
						</label>
						<input type="text" className="form-control" id="up_lastname" />
					</div>
				</div>
				<div className="row m-2">
					<div className="col-xs-12">
						<label
							htmlFor="email"
							className="form-label float-start badge txt-dark"
						>
							{t("email.label", { ns: "login" })}
						</label>
						<input
							type="text"
							className="form-control"
							id="up_email"
							disabled
							readOnly
						/>
					</div>
				</div>

				<div className="row m-2">
					<div className="col-xs-12 col-md-6">
						<label
							htmlFor="username"
							className="form-label float-start badge txt-dark"
						>
							{t("phone-number.label", { ns: "login" })}
						</label>
						<input type="text" className="form-control" id="up_phone" />
					</div>
					<div className="col-xs-12 col-md-6">
						<label
							htmlFor="username"
							className="form-label float-start badge txt-dark"
						>
							{t("birth-date.label", { ns: "login" })}
						</label>
						<input type="date" className="form-control" id="up_birthDate" />
					</div>
				</div>
			</div>
		);
	}
	/**
	 * Renders the component
	 */
	return loaded ? (
		<>
			<div className="content-container content-margin-top" key={componentId}>
				<div className="content-margin-top" key={componentId}>
					<center>
						{<div className="app-side-margins ">{setCard()}</div>}
					</center>
				</div>
			</div>
			<div className="app-separator-extreme"></div>
			<CustomFooter></CustomFooter>
		</>
	) : null;
};

UserProfile.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string
};

export default UserProfile;
