/**
 * ! Change LoginPage by your component name
 */

import React, { useState, useEffect } from "react";
import CustomSpinner from "../common/shared/custom-spinner.js";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);

import HeadMenu from "../common/headers/head-menu.js";
import CustomFooter from "../common/shared/custom-footer.js";
import LoginForm from "../common/forms/login-form.js";

import { setTheme, getTheme } from "../models/themes.js";
import {
	logingWithLocalDb,
} from "../../data/services/load-data-service.js";


const LoginPage = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-users-" + ticks;
	const [userEmail, setEmail] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	const _userModel = require("../models/user.js");

	const navigate = useNavigate();
	const gotoDahsboard = () => navigate(`/app`);

	// Specific
	setTheme(getTheme());
	useEffect(() => {
		props = _props.properties;
	}, [loaded]);

	componentDidMount();

	async function componentDidMount() {
		try {
			props = _props.properties;
			await loadAll();
			//	console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}

	async function loadAll() {
		if (loaded) return;
		var logged = await logingWithLocalDb();
		if (logged && global.LOGGED_USER != null) {
			setEmail(global.LOGGED_USER.Email);
			console.log("Welcome " + userEmail);
			gotoDahsboard();
		}

		_logger.DEBUG(
			`Loaded ${props.pageName} with id ${componentId} `
		);
		setLoaded(true);
	}
	function submitLogin() {
		_userModel.getSetUser(global.LOGGED_USER);
		// reload url
		window.location.reload();
	}

	function onError(error) {
		sendToast(
			`User Profile ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App ">
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 app-primary-bg-color txt-primary ",
					textClass: "",
					menuButtonClass: "txt-primary m-2",
				}}
			/>
			<div className="app-side-margins" key={componentId}>
				<div className="content-container content-margin-top" key={componentId}>
					<div className="row" key={componentId}>
						<div className="col">
							<LoginForm
								properties={{
									pageName: "login",
									trNamespace: "login",
									submitFunction: submitLogin
								}}
							></LoginForm>
						</div>
					</div>
				</div>
			</div>
			<CustomFooter></CustomFooter>
		</div >

	);
};

LoginPage.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	base64Image: PropTypes.string
};

export default LoginPage;
