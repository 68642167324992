/**
 * REACT
 */
import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
/**
 * Services
 */
import {
	LoadDataProducts, LoadDataCompany,
} from "../../data/services/load-data-service.js";

import productService from "../../data/services/application/product.js";
/**
 * Components
 */
import CustomSpinner from "../common/shared/custom-spinner.js";
import HeadMenu from "../common/headers/head-menu.js";
import SwipeItem from "../common/specific/swipe-item.js";
import CategoryCard from "../common/specific/category-card.js";
import OrderManagement from "../common/specific/order-management.js";
import TipsComponent from "../common/specific/tips-component.js";
import CustomFooter from "../common/shared/custom-footer.js";
/**
 * Models
 */
//const _companyModel = require("../models/company.js");

import { setTheme, getTheme } from "../models/themes.js";
/**
 * Constants
 */
const isBrowser = typeof window !== `undefined`;

/**
 * ProductsPage
 * @param {*} _props
 * @returns
 */
const ProductsPage = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [userEmail, setUserEmail] = useState(null);
	const [productDatas, setProductDatas] = useState(null);
	const [categoriesDatas, setCategoriesDatas] = useState(null);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	// Show params
	const effectRan = useRef(false);
	const navigate = useNavigate();

	const _model = require("../models/dashboard.js");
	useScrollPosition();
	var isClick = false;

	// Specific
	setTheme(getTheme());

	/**
	 * Use effect
	 */
	useEffect(() => {
		if (!effectRan.current) {
			props = _props.properties;
			// Reload data on loaded state change.
			componentDidMount();
		}
		return () => {
			effectRan.current = true;
		};
	}, [loaded]);
	/**
	 * Component did mount
	 */
	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			onError(e);
		}
	}
	/**
	 * Load all datas
	 */
	async function loadAll() {
		var company = global.COMPANY_DATAS;
		if (company == null) {
			company = await LoadDataCompany();
			global.COMPANY_DATAS = data;
			setTheme(company.company.CompanyParams);
			//console.log(data);
			props.pageName = company.companyName;
			props.base64Image = company.companyDetails.CompanyLogo;

		}
		var data = await LoadDataProducts();
		if (data != null) {
			setUserEmail(data.name);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName} logged user ${userEmail}`
			);
			document.title = props.pageName;
			console.log(isBrowser);
			setProductDatas(data.products);
			setCategoriesDatas(data.categories);
			// wait for seconds
			setTimeout(() => {
				setLoaded(true);
			}, 50);
		} else {
			setTimeout(async () => {
				await loadAll();
			}, 500);
		}
	}
	/**
	 *  On error
	 */
	function onError(error) {
		//console.log(error);
		if (process.env.REACT_APP_ENV == "development") {
			handleShowToast(
				"error",
				t("error", { ns: "common" }),
				`ProductsPage ${error}`
			);
		}
		navigate("/");
	}
	/**
	 * Get the scroll position
	 * @param {*} param0
	 * @returns
	 */
	function getScrollPosition({ element, useWindow }) {
		if (!isBrowser) return { x: 0, y: 0 };

		const target = element ? element.current : document.body;
		const position = target.getBoundingClientRect();

		return useWindow
			? { x: window.scrollX, y: window.scrollY }
			: { x: position.left, y: position.top };
	}
	/**
 * Use scroll position
 * @param {*} deps
 * @param {*} element
 * @param {*} useWindow
 * @param {*} wait
 */
	function useScrollPosition(deps, element, useWindow, wait) {
		const position = useRef(getScrollPosition({ useWindow }));

		let throttleTimeout = null;

		const callBack = () => {
			if (isClick) return;
			const currPos = getScrollPosition({ element, useWindow });
			var items = document.querySelectorAll(".cat-item-title");
			//var actualPos = pageHeight + currPos.y;
			for (var i = 0; i < items.length; i++) {
				var item = items[i];
				var rect = item.getBoundingClientRect();
				var top = rect.top;
				var category = item.getAttribute("data-category");
				// set radio checked
				var name = `btn-${category}`;
				var button = document.getElementsByName(name)[0];
				// get button index
				if (top > 130 && top < 360) {
					if (button != null) {
						var btns = document.querySelectorAll(".btn-breadcumb-category");
						// foreach btns remove :active state
						btns.forEach((btn) => {
							// active state
							btn.classList.remove("active");
						});
						button.classList.add("active");
						document.getElementById("breadcumb-cat-scroller").scrollLeft =
							button.offsetLeft - 15;
					}
				}
			}
			position.current = currPos;
			throttleTimeout = null;
		};

		useLayoutEffect(() => {
			const handleScroll = () => {
				if (wait) {
					if (throttleTimeout === null) {
						throttleTimeout = setTimeout(callBack, wait);
					}
				} else {
					callBack();
				}
			};

			window.addEventListener("scroll", handleScroll);

			return () => window.removeEventListener("scroll", handleScroll);
		}, deps);
	}

	async function DeactivateFunct(item, isProduct, variantName) {
		if (variantName != null) {
			if (item.ProductComplements != null && item.ProductComplements.length > 0) {

				var variants = item.ProductComplements[0].ProductVariant;
				// get variant in variants with name variantName
				var variant = variants.filter((variant) => {
					if (variant.VariantName == variantName) {
						return variant;
					}
				});
				if (variant != null && variant.length > 0) {
					setProductState(item, "danger", "info", variantName);
					//replace variant in variants with this one
					for (i = 0; i < item.ProductComplements[0].ProductVariant.length; i++) {
						if (item.ProductComplements[0].ProductVariant[i].VariantName == variantName) {
							item.ProductComplements[0].ProductVariant[i].deactivated = true;
						}
					}
					await productService.UpdateDeactivated(item);
				}
			}
		}
		else {
			if (isProduct) {
				setProductState(item, "danger", "info");
				if (item.ProductComplements != null && item.ProductComplements.length > 0) {
					if (item.ProductComplements[0].ProductVariant != null && item.ProductComplements[0].ProductVariant.length > 0) {
						for (var i = 0; i < item.ProductComplements[0].ProductVariant.length; i++) {
							setProductState(item, "danger", "info", item.ProductComplements[0].ProductVariant[i].VariantName);
							item.ProductComplements[0].ProductVariant[i].deactivated = true;
						}
					}
				}
				item.ProductOptions[0].Deactivated = true;
				await productService.UpdateDeactivated(item);

			} else {
				var catid = item.Id;
				// get productDatas
				var products = productDatas.filter((product) => {
					if (product.CategoryId == catid) {
						return product;
					}
				});
				for (i = 0; i < products.length; i++) {
					var _item = products[i];
					await DeactivateFunct(_item, true, variantName);
				}
			}
		}
	}
	async function ActivateFunct(item, isProduct, variantName) {
		if (variantName != null) {
			//
			if (item.ProductComplements != null && item.ProductComplements.length > 0) {

				var variants = item.ProductComplements[0].ProductVariant;
				// get variant in variants with name variantName
				var variant = variants.filter((variant) => {
					if (variant.VariantName == variantName) {
						return variant;
					}
				});
				if (variant != null && variant.length > 0) {
					setProductState(item, "info", "danger", variantName);
				}
				for (i = 0; i < item.ProductComplements[0].ProductVariant.length; i++) {
					if (item.ProductComplements[0].ProductVariant[i].VariantName == variantName) {
						item.ProductComplements[0].ProductVariant[i].deactivated = false;
					}
				}
				await productService.UpdateDeactivated(item);
			}
		}
		else {
			if (isProduct) {
				setProductState(item, "info", "danger");
				if (item.ProductComplements != null && item.ProductComplements.length > 0) {

					if (item.ProductComplements[0].ProductVariant != null && item.ProductComplements[0].ProductVariant.length > 0) {
						for (var i = 0; i < item.ProductComplements[0].ProductVariant.length; i++) {
							setProductState(item, "info", "danger", item.ProductComplements[0].ProductVariant[i].VariantName);
							item.ProductComplements[0].ProductVariant[i].deactivated = false;
						}
					}
				}
				item.ProductOptions[0].Deactivated = false;
				await productService.UpdateDeactivated(item);

			} else {
				var catid = item.Id;
				// get productDatas
				var products = productDatas.filter((product) => {
					if (product.CategoryId == catid) {
						return product;
					}

				});
				for (i = 0; i < products.length; i++) {
					var _item = products[i];
					await ActivateFunct(_item, true, variantName);
				}
			}
		}
	}

	function setProductState(item, classAdd, classRemove, variantName) {
		var compid = `product-item-${item.Product}-${item.Id}`
		if (variantName != null) {
			compid = `product-item-${item.Product}-${item.Id}-${variantName}`
		}
		var comp = document.getElementById(compid);
		if (comp != null) {
			comp.classList.remove("bg-" + classRemove);
			comp.classList.add("bg-" + classAdd);
		}
	}


	/**
	 * Set the menus from datas
	 */
	function setMenus() {
		//console.log("setMenus()");
		// Get all categories distinct from companyDatas
		var previousId = 0;
		// Create dateTime now ticks
		var ticks = new Date().getTime();
		return (
			<>
				<div className="m-2 ">
					{categoriesDatas.map((category) => {
						var id = "c_" + ticks + "_" + category.Id;
						previousId++;
						return setCategory(category, id, previousId);
					})}
				</div>
			</>
		);
	}

	/**
	 * Set the breadcumb menu
	 * @returns
	 */
	function setBreadcumbMenu() {
		//console.log("setArianneMenu()");
		var list = categoriesDatas.map((item, count) => {
			count++;
			return (
				<button
					type="button"
					key={`category_btn_${item.CategoryName}_${item.Id}-${count}`}
					className={`btn btn-outline-primary-no-focus btn-breadcumb-category m-2 mt-0 mb-0 app-text-color animate spin delay-${count}`}
					name={`btn-${item.CategoryName}`}
					id={`option${count}`}
					data-category={item.CategoryName}
					data-category-position={item.PositionCategory}
					data-category-index-position={count}
					data-toggle="button"
					aria-pressed="false"
					onClick={(e) => {
						var category = e.target.getAttribute("data-category");
						var position = e.target.getAttribute("data-category-position");
						// scroll to element id `category-item-${category.Name}-${category.position}`
						var element = document.getElementById(
							`category-item-${category}-${position}`
						);
						// remove active to all buttons
						var btns = document.querySelectorAll(".btn-breadcumb-category");
						btns.forEach((btn) => {
							// active state
							btn.classList.remove("active");
						});
						// add active to button
						e.target.classList.add("active");
						isClick = true;
						if (element) {
							element.scrollIntoView({ behavior: "smooth" });
						}
						setTimeout(() => {
							isClick = false;
						}, 750);
					}}
				>
					{item.CategoryName}&nbsp;
					<i className="fas fa-angle-down"></i>
				</button>
			);
		});
		return (
			<div
				key="breadcumb-cat-scroller"
				className="flex-row flex-nowrap horizontal-scroller"
				id="breadcumb-cat-scroller"
			>
				{list}
			</div>
		);
	}
	/**
	 * Set the category from datas
	 * @param {} category
	 * @param {*} id
	 * @returns
	 */
	function setCategory(
		category,
		id,
		previousId,
		additionalCardClassName = null
	) {
		//console.log("setCategory()");
		var categoryItems = productDatas.filter((item) => {
			if (item.CategoryId == category.Id) {
				return item;
			}
		});
		// sort categoryItems by position then by Name
		categoryItems = _model.orderCategoryItems(categoryItems);
		var _class = "scroll-anchor";
		if (previousId <= 1) {
			_class = "scroll-anchor-first";
		}
		var child = (<CategoryCard
			properties={{
				pageName: props.pageName,
				trNamespace: props.trNamespace,
				category: category,
				count: id,
				additionalCardClassName: additionalCardClassName

			}}
		></CategoryCard>);
		return (<>

			{setTips("tuto-slide-categories", id)}
			<div
				className={`${_class} cat-item-title`}
				id={`category-item-${category.CategoryName}-${category.PositionCategory}`}
				key={`category-item-${category.CategoryName}-${category.Id}`}
				data-category={`${category.CategoryName}`}
			>
				<div
					className={`row`}
					key={`category-item-${category.CategoryName}-${id}-ct`}
				>
					<SwipeItem
						key={`${category.Id}-${id}`}
						properties={{
							item: category,
							child: child,
							itemName: category.CategoryName,
							trNamespace: props.trNamespace,
							fullLeftAction: () => { ActivateFunct(category, false) },
							fullLeftActionName: t("activate.category", { ns: props.trNamespace }),
							fullRightAction: () => { DeactivateFunct(category, false) },
							fullRightActionName: t("deactivate.category", { ns: props.trNamespace })
						}}
						className="col-12 col-md-6 col-lg-4 col-xl-3"
					>

					</SwipeItem>
				</div>
				{setTips("tuto-slide-products", `${id}`)}
				<div className="row">
					{categoryItems.map((item, count) => {
						count++;
						return setProductCard(item, count);
					})}
				</div>
				<div className="m-2"></div>
			</div></>
		);
	}
	/**
	 * Set the product card
	 * @param {*} item
	 * @returns
	 */
	function setProductCard(item, count) {
		// if item has variants
		var variantsItem = [];
		if (item.ProductComplements != null && item.ProductComplements.length > 0) {

			var variants = item.ProductComplements[0].ProductVariant;
			if (variants != null && variants.length > 0) {

				for (var i = 0; i < variants.length; i++) {
					var variant = variants[i];
					var _border = 'bg-info ';
					if (item.ProductOptions != null && item.ProductOptions.length > 0) {
						if (item.ProductOptions[0].deactivated == true) {
							_border = "bg-danger ";
						}
					}
					if (variant.deactivated) {
						_border = 'bg-danger';
					}
					// For each variant create a swipe item
					var variantChild = (

						<div className={`card-header w-100 border-rounded ${_border}`} id={`product-item-${item.Product}-${item.Id}-${variant.VariantName}`} >
							<div className="row">
								<div className="col-12 d-flex align-items-start">
									<h2 className="m-2">{variant.VariantName}</h2>
								</div>
							</div>
							{setSliders()}
						</div>
					)
					var variantComp = (
						<div className={`row mt-2 mb-2 animate slide delay-${count}`} key={`${item.Id}-${count}`}>
							<div className="col-sm-12"><SwipeItem
								properties={{
									item: item,
									itemName: variant.VariantName,
									trNamespace: props.trNamespace,
									child: variantChild,
									variantName: variant.VariantName,
									fullLeftAction: (vName) => {
										ActivateFunct(item, true, vName)
									},
									fullLeftActionName: t("activate.product", { ns: props.trNamespace }),
									fullRightAction: (vName) => {
										DeactivateFunct(item, true, vName)
									},
									fullRightActionName: t("deactivate.product", {
										ns: props.trNamespace
									})
								}}
								className={`col-12 col-md-6 col-lg-4 col-xl-3 ${bg}`}
							>
							</SwipeItem></div></div>);
					variantsItem.push(variantComp);
				}

			}
		}
		var bg = "bg-info ";
		if (item.ProductOptions != null && item.ProductOptions.length > 0) {
			if (item.ProductOptions[0].Deactivated == true) {
				bg = "bg-danger";
			}
		}
		var child = (
			<div className={`card-header w-100 h-100 border-rounded ${bg}`} id={`product-item-${item.Product}-${item.Id}`}>
				<div className="row">
					<div className="col">
						<h2 className="m-2 text-start">{item.Product}</h2>
					</div>
				</div>
				{setSliders()}
			</div>

		)
		var comp = (
			<>
				<div className={`mt-2 mb-2 pt-1 pb-1 animate slide delay-${count}`} key={`${item.Id}-${count}`}>

					<SwipeItem
						properties={{
							item: item,
							itemName: item.Product,
							trNamespace: props.trNamespace,
							child: child,
							fullLeftAction: () => { ActivateFunct(item, true) },
							fullLeftActionName: t("activate.product", { ns: props.trNamespace }),
							fullRightAction: () => { DeactivateFunct(item, true) },
							fullRightActionName: t("deactivate.product", {
								ns: props.trNamespace
							})
						}}
						className={`col-sm-12 col-md-6 col-lg-4 col-xl-3 ${bg}`}
					>
					</SwipeItem></div>
				{variantsItem.map((variantItem, count) => {
					count++;
					return (
						<div className="row mt-2 mb-2 m-3 w-100" key={`variant-${item.Product}-${item.Id}-${count}`}>
							{variantItem}
						</div>);
				})}	</>

		);

		return comp
	}
	function setTips(name, id) {
		return (
			<TipsComponent
				properties={{
					pageName: props.pageName,
					trNamespace: "products",
					name: name,
					id: id,
					color: "success"
				}}
			>

			</TipsComponent >)
	}
	function setSliders() {
		return (
			<div className="row m-2 mt-0 mb-0">
				<div className="col-6">
					<h6 className="float-start">
						<span className="d-flex align-items-start opacity-mid m-2 mt-0 mb-0">
							<span className=" badge bg-white " style={{ maxWidth: "100px", textWrap: "wrap" }}>
								<span className="text-info">{t("stock", { ns: props.trNamespace })}</span>
							</span>
							<span>
								<i className="fas text-white fa-caret-right animate-loop animate-left"></i>
							</span>
						</span>
					</h6>
				</div>
				<div className="col-6">
					<h6 className="float-end">
						<span className="d-flex align-items-end opacity-mid m-0">
							<span>
								<i className="fas text-white fa-caret-left animate-loop animate-right"></i>
							</span>
							<span className=" badge bg-white end-0" style={{ maxWidth: "100px", textWrap: "wrap" }}>
								<span className="text-danger">{t("out-of-stock", { ns: props.trNamespace })}</span>
							</span>
						</span>
					</h6>
				</div>
			</div>

		)
	}
	/**
	 * Set the component
	 */
	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (

		<div className="App ">
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 app-primary-bg-color txt-primary ",
					textClass: "",
					menuButtonClass: "txt-primary m-2",
				}}
			/>
			<div className="hilightbg-sticky-top-header bg-secondary-gradient shadow  h-100">
				{setBreadcumbMenu()}
			</div>
			<div
				className="content-container content-margin-top h-100"
				key={componentId}
				style={{ scrollX: "hidden" }}
			>
				<div className="app-side-margin p-0">
					<div className="row"><div className="col-12">
						<OrderManagement
							properties={{
								pageName: props.pageName,
								trNamespace: props.trNamespace
							}}
						>
						</OrderManagement >
					</div></div>
					<div className="row ">
						<div className="col-12">
							{setMenus()}</div>
					</div></div>
				<div className="app-separator-large"></div></div >

			<CustomFooter></CustomFooter>

		</div>
	);

};

ProductsPage.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	backgroundImage: PropTypes.string,
	translatePageTitle: PropTypes.bool,
	showHeaderTooltip: PropTypes.bool,
	base64Image: PropTypes.string
};

export default ProductsPage;
