//*
// ! Replace area by the name of the model
//*
import ApiRouter from "../../routes/datas/company-images.js";
import commons from "../common-services-funct.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
const _ServiceName = "company";
const _CompanyModel = require("../../../components/models/company.js");
const company = _CompanyModel.getCompanyDatas();

async function GetAll(token) {
	var data = null;
	try {
		try {
			data = await commons.GetAll(ApiRouter.GetAll(), true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetAll Axios error", [e]);
		}

	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetAll error", [err]);
	}
	return data;
}
async function GetFilterLocal() {
	var data = await GetAll();
	return data;
}
async function GetFilter() {
	var id = company.companyId;
	var data = null;
	try {
		try {
			data = await commons.GetFilter(
				ApiRouter.GetFilter() + "?CompanyId=" + id,
				true
			);
		} catch (e) {
			_logger.DEBUG(
				_ServiceName + " service GetFilter " + id + " Axios error",
				[e]
			);
		}

	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetFilter " + id + " error", [err]);
	}
	return data;
}
async function GetOne(id, token) {
	var data = null;
	try {
		try {
			data = await commons.GetOne(
				ApiRouter.GetOne() + "?Id=" + id,
				true,
				token
			);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service GetOne " + id + " Axios error", [
				e
			]);
		}

	} catch (err) {
		_logger.ERROR(_ServiceName + " service GetOne " + id + " error", [err]);
	}
	return data;
}

async function CreateOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.CreateOne(ApiRouter.createOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service CreateOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service CreateOne " + "error", [model, err]);
	}
	return data;
}

async function UpdateOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.UpdateOne(ApiRouter.UpdateOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service UpdateOne " + "Axios error", [
				model,
				e
			]);
		}

	} catch (err) {
		_logger.ERROR(_ServiceName + " service UpdateOne " + "error", [model, err]);
	}
	return data;
}

async function DeleteOne(model, token) {
	var data = null;
	try {
		try {
			data = await commons.DeleteOne(ApiRouter.DeleteOne(), model, true, token);
		} catch (e) {
			_logger.DEBUG(_ServiceName + " service DeleteOne " + "Axios error", [
				model,
				e
			]);
		}
	} catch (err) {
		_logger.ERROR(_ServiceName + " service DeleteOne " + "error", [model, err]);
	}
	return data;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	CreateOne,
	UpdateOne,
	DeleteOne,
	GetFilterLocal
};
