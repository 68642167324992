import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/application/product";

function GetAll() {
	const route = `${baseUrl}/`;
	return route;
}
function GetFilter() {
	const route = `${baseUrl}/filter`;
	return route;
}
function GetOne() {
	const route = `${baseUrl}/one`;
	return route;
}
function PostCreate() {
	const route = `${baseUrl}/create`;
	return route;
}
function UpdateOne() {
	const route = `${baseUrl}/update`;
	return route;
}
function PutDelete() {
	const route = `${baseUrl}/delete`;
	return route;
}

function UpdateDeactivated(){
	const route = `${baseUrl}/update-deactivated`;
	return route;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	PostCreate,
	UpdateOne,
	PutDelete,
	UpdateDeactivated
};
