/**
 * ! Change TipsComponent by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";


const TipsComponent = (_props) => {
	var props = _props.properties;
	const componentId = "TipsComponent-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	function componentDidMount() {
		try {
			setLoadFunction(true);
		} catch (e) {
			onError(e);
		}
	}
	// Execute after load until all is ok.
	function setLoadFunction(data) {
		try {
			if (data != null) {
				//props = _props.properties;
				if (props.color == null || props.color == "") {
					props.color = "info";
				}
				setLoadedState(data);
			}
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	function setTips() {
		return (
			<div className="row" key={componentId} data-type="tip-components">
				<div className="accordion mt-1 mb-1" id={`acc-${props.name}-${props.id}`}>
					<div className="accordion-item" style={{ border: "none" }}>
						<button className={`btn btn-circle btn-${props.color} btn-small h-100 float-start`} type="button" aria-expanded="true"
							onClick={
								(e) => {
									var element = document.getElementById(`coll-${props.name}-${props.id}`);
									// if has class show
									if (element.classList.contains("show")) {
										element.classList.remove("show");
									}
									else {
										element.classList.add("show");
										setTimeout(() => {
											//click it if has class show
											if (element.classList.contains("show"))
												e.target.click();
										}, 7000);
									}

								}
							}
						>
							<i className="fas fa-question"></i>
						</button>
						<div id={`coll-${props.name}-${props.id}`} className="accordion-collapse collapse bg-transparent" data-bs-parent={`acc-${props.name}-${props.id}`}>
							<div className={`accordion-body bg-${props.color} border-rounded animate blur`}>
								<p className="m-auto h1-mob">{t(props.name, { ns: props.trNamespace })}</p>
							</div>
						</div>
					</div>
				</div >
			</div >)
	}

	return loaded ? (
		setTips()
	) : null;
};

TipsComponent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	name: PropTypes.string,
	id: PropTypes.number,
	color: PropTypes.string
};

export default TipsComponent;
