import {
    LeadingActions,
    SwipeableList,
    SwipeableListItem,
    SwipeAction,
    TrailingActions,
} from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import PropTypes from "prop-types";
import { useState } from "react";



const SwipeItem = (_props) => {
    var props = _props.properties;
    const [progress, setProgress] = useState(0);

    if (props.fullLeftColorClass == null) props.fullLeftColorClass = "btn-info";
    if (props.fullRightColorClass == null) props.fullRightColorClass = "btn-danger";

    const handleSwipeEnd = (dragDirection) => {
        if (progress > 30) {
            if (dragDirection === "right") {
                props.fullLeftAction(props.variantName ? props.variantName : "");
            } else {
                props.fullRightAction(props.variantName ? props.variantName : "");
            }
        }
    };
    const setSwipeProgress = (progress) => {
        setProgress(progress);
    };

    const leadingActions = () => (
        <LeadingActions>
            <SwipeAction
                className={`btn ${props.fullLeftColorClass} h-100 m-0`}
                destructive={false}
                onClick={() => props.fullLeftAction(props.variantName ? props.variantName : "")}>
                <h5> {props.fullLeftActionName}</h5>
            </SwipeAction>
        </LeadingActions>
    );

    const trailingActions = () => (
        <TrailingActions>
            <SwipeAction
                className={`btn ${props.fullRightColorClass} h-100 m-0`}
                destructive={false}
                onClick={() =>
                    props.fullRightAction(props.variantName ? props.variantName : "")
                }
            >
                <h5>{props.fullRightActionName} </h5>
            </SwipeAction>
        </TrailingActions >
    );
    return (
        <SwipeableList className='w-100'>
            <SwipeableListItem
                leadingActions={leadingActions()}
                trailingActions={trailingActions()}
                className={props.child ? 'w-100' : ''}
                onSwipeEnd={handleSwipeEnd}
                onSwipeProgress={setSwipeProgress}
            >

                {props.child != null ? props.child : (<p className='w-100 h-100 p-4 m-2 border-rounded shadow h0-mob'>
                    {props.itemName}</p>)}


            </SwipeableListItem>
        </SwipeableList>
    )
};

SwipeItem.propTypes = {
    itemName: PropTypes.string,
    trNamespace: PropTypes.string,
    item: PropTypes.object,
    fullLeftAction: PropTypes.func,
    fullLeftActionName: PropTypes.string,
    fullRightAction: PropTypes.func,
    fullRightActionName: PropTypes.string,
    fullLeftColorClass: PropTypes.string,
    fullRightColorClass: PropTypes.string,
    child: PropTypes.object,
    variantName: PropTypes.string,
};
export default SwipeItem;