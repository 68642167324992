/**
 * ! Change UserOrdersPage by your component name
 */

import React, { useState, useEffect } from "react";
import { LoadDetailsData } from "../../data/services/load-data-service.js";
import CustomSpinner from "../common/shared/custom-spinner.js";
import PropTypes from "prop-types";
import { useToastContext } from "../../context/toast-context.js";
import { initTranslations } from "../../i18n/i18n.js";
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);

import HeadMenu from "../common/headers/head-menu.js";
import UserOrders from "../common/specific/user-orders.js";

import { setTheme, getTheme } from "../models/themes.js";
const _basketModel = require("../models/basket.js");

const UserOrdersPage = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-users-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [canOrder, setCanOrder] = useState(false);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	// Specific
	setTheme(getTheme());

	useEffect(() => {
		props = _props.properties;
	}, [loaded]);

	componentDidMount();

	async function componentDidMount() {
		try {
			props = _props.properties;
			await loadAll();
			//	console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}

	async function loadAll() {
		if (loaded) return;
		var data = await LoadDetailsData();
		if (data != null && data != undefined && data.companyName != null) {
			props.pageName = data.companyName;
			props.base64Image = data.companyDetails.CompanyLogo;
			setTheme(data.company.CompanyParams);
			setCanOrder(data.company.CompanyParams.CanOrder);
			_logger.DEBUG(
				`Loaded ${props.pageName} with id ${componentId} and company ${data.companyName}`
			);
			document.title = props.pageName;
			//_basketModel.setBasketPriceAndQty(null, data.companyDetails.Currency);
			// wait for seconds
			setTimeout(() => {
				setLoaded(true);
				setTimeout(() => {
					_basketModel.setBasketPriceAndQty({
						currency: data.companyDetails.Currency,
						cpid: data.company.CompanyId
					});
				}, 1000);
			}, 150);
		} else {
			setTimeout(async () => {
				await loadAll();
			}, 500);
		}
	}

	function onError(error) {
		sendToast(
			`User Profile ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	return !loaded ? (
		<div className="App bg-primary h-100">
			<header className="App-header">
				<CustomSpinner
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</header>
		</div>
	) : (
		<div className="App ">
			<HeadMenu
				properties={{
					pageName: "header",
					trNamespace: "headMenu",
					image: props.base64Image,
					title: props.pageName,
					addedClass: "w-100 app-primary-bg-color txt-primary ",
					textClass: "",
					menuButtonClass: "txt-primary m-2",
					showOrders: canOrder
				}}
			/>
			<div className="app-side-margins" key={componentId}>
				<UserOrders
					properties={{
						pageName: props.pageName,
						trNamespace: props.trNamespace
					}}
				/>
			</div>
		</div>
	);
};

UserOrdersPage.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	icon: PropTypes.string,
	base64Image: PropTypes.string
};

export default UserOrdersPage;
