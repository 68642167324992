import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";

//import { StrictMode } from "react";
// Context
import { ToastContextProvider } from "./context/toast-context.js";
import { Notifications } from '@bdhamithkumara/react-push-notification';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Components
import Dashboard from "./components/pages/dashboard.js";
import LoginPage from "./components/pages/login-page.js";
import Orders from "./components/pages/orders.js";
import UserProfilePage from "./components/pages/users-profile.js";
import UserOrdersPage from "./components/pages/users-orders.js";
import App from "./App";
// Global vars
import "./data/globals/globals.js";

import "https://kit.fontawesome.com/ead315ea0a.js";
import "./rits-package/stylesheets/style.css";
import "./assets/styles/fonts.css";
import ProductsPage from "./components/pages/products-page.js";

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

var cpid = -1;


function setDefault() {
	if (process.env.REACT_APP_COMPANY_ID != null) {
		return (
			<Dashboard
				properties={{
					pageName: global.cpname,
					trNamespace: "dashboard",
					icon: "fa-buildings",
					translatePageTitle: false,
					showHeaderTooltip: false
				}}
			/>
		);
	} else {
		return <LoginPage properties={{
			pageName: global.cpname,
			trNamespace: "dashboard",
			icon: "fa-buildings",
			translatePageTitle: false,
			showHeaderTooltip: false
		}} />;
	}
}
root.render(
	//<StrictMode>

	<ToastContextProvider>

		<Notifications />
		<ToastContainer
			stacked
			position="bottom-left"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="light"
		/>
		<BrowserRouter>
			<Routes>
				<Route path={`/`} element={setDefault()} />
				<Route
					path={`/login`}
					element={
						<LoginPage
							properties={{
								pageName: global.cpname,
								trNamespace: "cart"
							}}
						/>
					}
				/>
				<Route
					path={`/orders`}
					element={
						<Orders
							properties={{
								pageName: global.cpname,
								trNamespace: "orders",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/app`}
					element={
						<App
							properties={{
								pageName: global.cpname,
								trNamespace: "dashboard",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/dashboard`}
					element={
						<Dashboard
							properties={{
								pageName: global.cpname,
								trNamespace: "dashboard",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/products`}
					element={
						<ProductsPage
							properties={{
								pageName: "products",
								trNamespace: "products",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/user-profile`}
					element={
						<UserProfilePage
							properties={{
								pageName: "user-profile",
								trNamespace: "common",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
				<Route
					path={`/${cpid}/user-orders`}
					element={
						<UserOrdersPage
							properties={{
								pageName: "user-orders",
								trNamespace: "common",
								icon: "fa-buildings",
								translatePageTitle: false,
								showHeaderTooltip: false
							}}
						/>
					}
				/>
			</Routes>
		</BrowserRouter>
	</ToastContextProvider >
	//</StrictMode>
);
reportWebVitals();
