import { RootDeskApis } from "../main-route";
/**
 * Retrieves the API endpoint for getting users data.
 * @returns {string} The API endpoint URL.
 */
const baseUrl = RootDeskApis() + "/application/order";

function GetAll() {
	const route = `${baseUrl}/`;
	return route;
}
function GetFilter() {
	const route = `${baseUrl}/filter`;
	return route;
}
function GetOne() {
	const route = `${baseUrl}/one`;
	return route;
}
function PostCreate() {
	const route = `${baseUrl}/create`;
	return route;
}
function PutUpdate() {
	const route = `${baseUrl}/update`;
	return route;
}
function PutDelete() {
	const route = `${baseUrl}/delete`;
	return route;
}
function GetLatest() {
	const route = `${baseUrl}/latest-only`;
	return route;
}
function SetStatus() {
	const route = `${baseUrl}/status`;
	return route;
}
function SetPrint() {
	const route = `${baseUrl}/print`;
	return route;
}
function SetPrintZ() {
	const route = `${baseUrl}/print-z`;
	return route;
}


export default {
	GetAll,
	GetFilter,
	GetOne,
	PostCreate,
	PutUpdate,
	PutDelete,
	GetLatest,
	SetStatus,
	SetPrint,
	SetPrintZ,
};
