/**
 * ! Change HeadMenu by your component name
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
//import { LoadData } from "../../../data/services/load-data-service.js";
//import { LogoBo2_80Animation } from "../../../properties/animations/logos-animations.js";
import { setTheme, getTheme } from "../../models/themes.js";

import {
	logingWithLocalDb,
	logout
} from "../../../data/services/load-data-service.js";

const HeadMenu = (_props) => {
	var props = _props.properties;
	props.showLogin = props.showLogin ?? true;
	const ticks = new Date().getTime();
	const componentId = "head-menu-" + ticks;
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [userEmail, setEmail] = useState(null);
	const [loaded, setLoadedState] = useState(false);
	var custom = process.env.REACT_APP_COMPANY_ID
		? process.env.REACT_APP_LOGO
		: require("../../../assets/commons/menu-128.png");
	const [setLogo, setNewLogo] = useState(props.image ?? custom);

	setTheme(getTheme());

	useEffect(() => {
		componentDidMount();
	}, [loaded, setLogo]);

	async function componentDidMount() {
		try {
			await loadAll();
		} catch (e) {
			onError(e);
		}
	}
	async function loadAll() {
		setTheme(getTheme());
		setNewLogo(custom);
		var logged = await logingWithLocalDb();
		if (logged && global.LOGGED_USER != null) {
			setEmail(global.LOGGED_USER.Email);
		}
		setLoadedState(true);
	}

	const start = (_logo, animate) => {
		if (props.image == null || props.image == "") _logo = false;
		var imgComp = (imgComp = (
			<img src={setLogo} className="img-s-responsive border-rounded"></img>
		));
		if (_logo) {
			if (props.image == "null" || props.image.startsWith("http")) {
				imgComp = <img src={setLogo} className="img-s-responsive"></img>;
			} else {
				imgComp = (
					<img
						src={`data:image/png;base64,${props.image}`}
						className="img-s-responsive border-rounded m-0"
					></img>
				);
			}
		}
		return (
			<a
				href={`/dashboard`}
				className={`${props.textClass} navbar-brand container-inline  ${animate ? "animate glow delay-1 nav-height-100}" : ""
					}`}
			>
				{imgComp}
				<h3 className="txt-header-title txt-primary font-bold left-0 m-1 mb-0 mt-0">
					{props.title}
				</h3>
			</a>
		);
	};
	const end = (
		<span className="h2-mob txt-primary">
			<i className="text-warning  font-bold fa-sharp fa-light fa-arrows-rotate"></i>
			&nbsp;{"reload"}
		</span>
	);
	function activateAccountMenu() {
		var el = document.getElementsByClassName("dropdown-menu-account");
		//if el has class show remove it
		if (el[0].style.display == "none") {
			el[0].style.display = "block";
		} else {
			el[0].style.display = "none";
		}
	}
	function setMenuItems() {
		if (props.showMenu == false) return "";
		var account = null;
		//if (props.showOrders) {
		if (userEmail != null) {
			account = (
				<li className="nav-item dropdown m-4 mb-0 mt-0 animate pop delay-4">
					<a
						className="nav-link txt-primary"
						href="#"
						role="button"
						data-bs-toggle="dropdown"
						aria-expanded="false"
						onClick={() => {
							activateAccountMenu();
						}}
					>
						<span className="h2-mob">

							<i className={` font-bold fas fa-user-gear`}></i>
							&nbsp;
							{userEmail}
							<i className={` m-2 mt-0 mb-0 font-bold fas fa-caret-down`}></i>

						</span>
					</a>
					<ul className="dropdown-menu dropdown-menu-account bg-primary-gradient  txt-primary w-100" style={{ display: "none" }}>
						<li>
							<a
								className="dropdown-item txt-primary"
								href={`${process.env.REACT_APP_APIS_URL}/front/home`}
								target="_blank"
								onClick={async () => {
									activateAccountMenu();
								}} rel="noreferrer"
							>
								<span className="h2-mob">
									<i className={` font-bold fas fa-user-large-slash`}></i>
									&nbsp;
									{t("common:admin-panel")}
								</span>
							</a>
						</li>
						<li>
							<a
								className="dropdown-item txt-primary"
								href="#"
								onClick={async () => {
									activateAccountMenu();
									var loggedOut = await logout();
									if (loggedOut) {
										window.location.href = `/dashboard`;
									}
								}}
							>
								<span className="h2-mob">
									<i className={` font-bold fas fa-user-large-slash`}></i>
									&nbsp;
									{t("common:logout")}
								</span>
							</a>
						</li>
					</ul>
				</li>
			);
		} else {
			if (props.showLogin) {
				account = (
					<li className="animate pop delay-4">
						<a
							className="nav-link active txt-primary trn"
							href="/login"
						>
							<span className="h2-mob">
								<i className={` font-bold fa-sharp fa-user-large-slash`}></i>
								&nbsp;{t("login", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
				);
			}
		}
		//}

		var uls = (
			<>
				<ul
					className="navbar-nav me-auto mb-2 mb-lg-0 p-4 pb-0 pt-0 w-100"
					style={{ left: "15px !important", marginLeft: "0 !important" }}
				>
					<li className="animate pop">
						<a
							className="nav-link active txt-primary trn"
							href={`/dashboard`}
						>
							<span className="h2-mob">								<i
								className={` font-bold fa-sharp fa-solid fa-grid-horizontal`}
							></i>
								&nbsp;{t("dashboard", { ns: props.trNamespace })}
							</span>
						</a>
					</li>
					{/* <li className="animate pop delay-2">
						<a
							className="nav-link active txt-primary trn"
							href={`/orders`}
						>
							<h4>
								<i className={` font-bold fa-sharp fa-solid fa-user-chef`}></i>
								&nbsp;{t("orders", { ns: props.trNamespace })}
							</h4>
						</a>
					</li>
					<li className="animate pop delay-2">
						<a
							className="nav-link active txt-primary trn"
							href={`/products`}
						>
							<h4>
								<i className={` font-bold fa-sharp fa-solid fa-user-chef`}></i>
								&nbsp;{t("products", { ns: props.trNamespace })}
							</h4>
						</a>
					</li> */}

					{account}
					<li className="animate pop delay-5">
						<a className="nav-link active trn" href={`/`}>
							{end}
						</a>
					</li>
				</ul>
			</>
		);
		return uls;
	}

	function onError(error) {
		sendToast(
			`From Head Menu ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}



	return loaded ? (
		<>
			<nav
				className="navbar navbar-expand-lg bg-primary-gradient txt-primary shadow fixed-top"
				key={componentId}
			>
				<div className="container-fluid container-inline-spaced">
					{start(true, false)}
					<div className="container-inline-spaced">
						<button
							className="navbar-toggler m-1 mb-0 mt-0"
							type="button"
							aria-controls="navbarText"
							aria-expanded="false"
							aria-label="Toggle navigation"
							onClick={() => {
								// Get element by id navbarSupportedContent
								var el = document.getElementById("navbarText");
								// if el has class show remove
								if (el.classList.contains("show")) {
									el.classList.remove("show");
								} else {
									el.classList.add("show");
								}
							}}
						>
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<div className="collapse navbar-collapse p-2 " id="navbarText">
						{setMenuItems()}
					</div>
				</div>
			</nav>
		</>
	) : (
		<nav
			className="navbar navbar-expand-lg bg-primary-gradient txt-primary  shadow fixed-top"
			key={componentId}
		>
			<div className="container-fluid container-inline-spaced">
				{start(true, true)}
			</div>
		</nav>
	);
};

HeadMenu.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	image: PropTypes.string,
	title: PropTypes.string,
	addedClass: PropTypes.string,
	textClass: PropTypes.string,
	menuButtonClass: PropTypes.string,
	showOrders: PropTypes.bool,
	// bool with default value = true
	showMenu: PropTypes.bool,
	showLogin: PropTypes.bool
};

export default HeadMenu;
