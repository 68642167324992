/**
 * ! Change OrderThumbnail by your component name
 */

import React from "react";
import PropTypes from "prop-types";
import { initTranslations } from "../../../i18n/i18n.js";

const OrderThumbnail = (_props) => {
	var props = _props.properties;
	const componentId =
		"OrderThumbnail-" + Math.random().toString(36).substring(7);

	const { t } = initTranslations();
	componentDidMount();

	function componentDidMount() {
		try {
			//console.log(`Loaded ${props.pageName} with id ${componentId}`);
		} catch (e) {
			onError(e);
		}
	}
	function onError(error) {
		console.log(error);
		//handleShowToast("error", t("error", { ns: props.trNamespace }), `${error}`);
	}
	function setImage() {
		return null;
	}
	function compIngredients({
		ingredients,
		header,
		color,
		elementId,
		compType,
		basketId
	}) {
		if (ingredients.length > 0) {
			return (
				<div className="row text-start">

					<div className="col-sm-12 m-1 font-bold">
						{ingredients.map((ingredient, count) => {
							count++;
							return (
								<span key={"ia-" + count} className="h2-mob">
									<span
										className={`m-1 p-2 border-rounded ${color}  font-bold`}
										id={`${elementId}-${ingredient.id}`}
										data-type={compType}
										data-bid={basketId}
										data-iid={ingredient.id}
									>
										{header}{" "}{ingredient.IngredientName}
									</span>
								</span>
							);
						})}
					</div>
				</div>
			);
		}
	}
	function setRemoveIngredients() {
		var ingredients = props.item.RemovedIngredientsList;
		return compIngredients({
			ingredients: ingredients,
			header: t("without-word", { ns: props.trNamespace }),
			color: "bg-danger",
			showPrice: false,
			elementId: `remove-order-ing-${props.item.ProductData.Id}`,
			compType: "remove-order-ing",
			basketId: props.item.ProductData.Id
		});
	}
	function setAddIngredients() {
		var ingredients = props.item.AddedIngredientsList;
		return compIngredients({
			ingredients: ingredients,
			header: "+",
			color: "bg-success",
			showPrice: true,
			elementId: `add-order-ing-${props.item.ProductData.Id}`,
			compType: "add-order-ing",
			basketId: props.item.ProductData.Id
		});
	}
	function setAdditionalProducts() {
		var additionalProducts = props.item.AdditionalProductsList;
		if (additionalProducts.length > 0) {
			return (
				<div className="row text-start mb-4">
					<div className="col-sm-12 font-bold">
						{additionalProducts.map((product, count) => {
							count++;

							return (
								<span key={"pa-" + count} className="h2-mob">
									<span
										className={`m-1 p-2 border-rounded text-primary font-bold`}
										id={`additional-product-${product.Id}`}
									>
										{product.Product}
									</span>
								</span>
							);
						})}
					</div>
				</div>
			);
		}
	}
	function setSelectedVariant() {
		var variant = props.item.Variant;
		if (variant != null) {
			variant = JSON.parse(variant);
			if (variant.variantName === t("orders:price")) return null;
			return (
				<span className="badge bg-info m-4 mt-0 mb-0">
					<strong>{variant.variantName}</strong>
				</span>

			);
		}
	}
	return (
		<div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
			<div
				className={`card text-center m-1 animate blur delay-${props.count++}`}
				key={`${componentId}`}
				id={`order-item-${props.item.ProductData.Id}`}
			>
				<div className="card-header bg-secondary">
					<div
						className={`container-inline animate glow delay-${props.count + 1}`}
					>
						{setImage()}
						<div className="start-0">
							<p className="card-title m-2  text-start h1-mob">
								<span className="font-bold">{props.item.TotalQty}</span>
								<span className="font-bold">x{" "}</span>
								<span className="font-bold">{props.item.ProductData.Product}</span>
								{setSelectedVariant()}
							</p>

						</div>
					</div>
				</div>
				<div className="card-body bg-light">
					<div className={`row animate slide delay-${props.count + 2}`}>
						{setAdditionalProducts()}
					</div>
					<div className={`row animate slide delay-${props.count + 4}`}>
						{setAddIngredients()}
					</div>
					<hr></hr>
					<div className={`row animate slide delay-${props.count + 3}`}>
						{setRemoveIngredients()}
					</div>
				</div>
			</div>
		</div>
	);
};

OrderThumbnail.propTypes = {
	item: PropTypes.object,
	trNamespace: PropTypes.string,
	count: PropTypes.number
};

export default OrderThumbnail;
