const lsName = "mo_user";

function getSetUser(user) {
	var __user = getOrSetUser(user);
	return __user;
}

function getOrSetUser(user) {
	var users = localStorage.getItem(lsName);
	var userJson = JSON.parse(users);
	var __user = null;
	if (users == null) {
		__user = user;
		__user.Password = "interleukin-il@10";
		localStorage.setItem(lsName, JSON.stringify(__user));
	} else {
		__user = userJson;
	}
	return __user;
}

function checkEmail(email) {
	var re = /\S+@\S+\.\S+/;
	if (!re.test(email)) {
		return false;
	}
	return true;
}

function checkPasswords(pwd) {
	// Test rules:
	// - at least 11 characters
	// - at least one number
	// - at least one uppercase letter
	// - at least one lowercase letter
	// - at least one special character
	var regex = /^((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{11,})$/.test(pwd);
	if (!regex) {
		return false;
	}
	return true;
}

function checkPhone(phone) {
	// Replace all but numbers
	phone = phone.replace(/\D/g, "");
	// Replace all spaces
	phone = phone.replace(/\s/g, "");
	// Regex phone
	var re = /^[0-9]{9,12}$/;
	if (!re.test(phone)) {
		return null;
	}
	phone = phone.replace(/(\d{2})/g, "$1 ");
	// Remove last space
	phone = phone.trim();
	return phone;
}

module.exports = { getSetUser, checkEmail, checkPasswords, checkPhone };
