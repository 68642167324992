const lsName = "mo_orders";
function emptyOrders() {
	localStorage.setItem(lsName, "[]");
}
function setOrders(ordersList, _name) {
	var idList = [];
	for (var i = 0; i < ordersList.length; i++) {
		var order = ordersList[i];
		if (!idList.includes(order.Id)) {
			idList.push(order.Id);
		}
	}
	var item = { name: _name, orders: idList };
	var allOrders = getOrders();
	var found = false;
	for (i = 0; i < allOrders.length; i++) {
		if (allOrders[i].name === _name) {
			allOrders[i] = item;
			found = true;
			break;
		}
	}

	if (!found) {
		allOrders.push(item);
	}

	localStorage.setItem(lsName, JSON.stringify(allOrders));
}

function getOrders() {
	var orders = localStorage.getItem(lsName);
	if (orders == null) {
		orders = [];
	} else {
		orders = JSON.parse(orders);
	}

	return orders;
}



module.exports = {
	emptyOrders,
	setOrders,
	getOrders,
};
