const lsName = "mo_options";
/**
 * Set company datas in local storage
 * @param {*} param0
 */
function setCommonOptions({ hideHelp }) {
	var item = { hideHelp: hideHelp };
	localStorage.setItem(lsName, JSON.stringify(item));
}
/**
 * Get company datas from local storage
 * @returns
 */
function getCommonOptions() {
	var item = localStorage.getItem(lsName);

	if (item == null) {
		item = { hideHelp: false };
	} else {
		item = JSON.parse(item);
	}
	return item;
}

module.exports = { setCommonOptions, getCommonOptions };
