/**
 * Initializes the i18n instance and sets up localization resources.
 * @module i18n
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useTranslation } from "react-i18next";

export var locale = "fr";

i18n.use(initReactI18next).init({
	fallbackLng: "en",
	lng: locale,
	debug: true,
	resources: {
		en: {
			common: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/common.json"),
			login: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/login.json"),
			dashboard: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/dashboard.json"),
			headMenu: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/head-menu.json"),
			menu: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/menu.json"),
			orders: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/orders.json"),
			products: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/products.json"),
			index: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/index.json"),
			modals: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/en/modals.json")
		},
		fr: {
			common: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/common.json"),
			login: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/login.json"),
			dashboard: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/dashboard.json"),
			headMenu: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/head-menu.json"),
			menu: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/menu.json"),
			orders: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/orders.json"),
			products: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/products.json"),
			index: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/index.json"),
			modals: require("./locales/" + process.env.REACT_APP_SITE_CONTEXT + "/fr/modals.json")
		}
	},
	ns: ["common", "users", "login", "dashboard", "headMenu", "index", "modals"],
	defaultNS: "common"
});

i18n.languages = ["en", "fr"];

/**
 * Initializes translations and returns the translation function and other translation utilities.
 * @function initTranslations
 * @returns {Object} The translation function and other translation utilities.
 */
export function initTranslations() {
	return useTranslation(["common", "users", "login", "dashboard", "headMenu"]);
}

/**
 * Sets the locale for the application.
 *
 * @param {string} newLocale - The new locale to set.
 */
export function setLocale(newLocale) {
	locale = newLocale;
	i18n.lng = newLocale;
	i18n.changeLanguage(newLocale);
}

export default i18n;
