const _deactivatedModel = require("./deactivated.js");
const lsName = "mo_baskets_";
/**
 * Set basket price and quantity
 */
function setBasketPriceAndQty({ addedProduct, currency, cpid }) {
	var __basket = getCpyBasket(cpid);
	if (addedProduct != null) {
		__basket.products.push(addedProduct);
		updateBaskets(__basket);
	}
	// for each item in addedProducts, get TotalPrice and TotalQty
	var totalPrice = 0;
	var totalQty = 0;
	__basket.products.forEach((item) => {
		totalPrice += parseFloat(item.TotalPrice);
		totalQty += parseFloat(item.TotalQty);
	});

	setBasketDatas(totalPrice, totalQty, currency);
}
/**
 * Get company basket
 * @param {*} cpid
 * @returns
 */
function getCpyBasket(cpid) {
	var __basket = getBaskets(cpid);
	checkProductsDeactivated(__basket);

	if (__basket == null) {
		localStorage.setItem(lsName + cpid, JSON.stringify(__basket));
	}
	return __basket;
}
/**
 * Delete basket for cpid
 * @param {*} cpid
 */
function deleteBasket(cpid) {
	localStorage.removeItem(lsName + cpid);
}
/**
 * Get baskets
 * @param {*} cpid
 * @returns
 */
function getBaskets(cpid) {
	var basketId = Number(new Date());
	var __baskets = localStorage.getItem(lsName + cpid);
	if (__baskets == null) {
		var basket = { cpid: cpid, basketId: basketId, products: [] };
		__baskets = JSON.stringify(basket);
		localStorage.setItem(lsName + cpid, __baskets);
	}

	__baskets = JSON.parse(__baskets);
	return __baskets;
}
/**
 * Update baskets
 * @param {*} basketCpy
 */
function updateBaskets(basketCpy) {
	// remove __baskets from localStorage
	localStorage.removeItem(lsName + basketCpy.cpid);
	// add __baskets to localStorage
	localStorage.setItem(lsName + basketCpy.cpid, JSON.stringify(basketCpy));
}
/**
 * Set basket datas
 * @param {*} totalPrice
 * @param {*} totalQty
 * @param {*} currency
 */
function setBasketDatas(totalPrice, totalQty, currency) {
	var price = "";
	if (totalQty > 0) {
		price = totalPrice.toFixed(2) + "&nbsp;" + (currency ?? "€");
		if (process.env.REACT_APP_IS_FRENCH == false) {
			price = (currency ?? "€") + "&nbsp;" + totalPrice.toFixed(2);
		}
	}
	var basketCount = document.querySelectorAll(`[data-id="basket-count"]`);
	basketCount.forEach((element) => {
		element.innerHTML = price;
	});
	var basketCountArticles = document.querySelectorAll(
		`[data-id="basket-count-articles"]`
	);
	basketCountArticles.forEach((element) => {
		if (totalQty > 0) {
			element.innerHTML = totalQty;
		} else {
			element.innerHTML = "";
		}
	});
	var basketMainIcon = document.querySelectorAll(
		'[data-id="basket-icon-main"]'
	);
	if (totalQty > 0) {
		basketMainIcon.forEach((element) => {
			// Remove attribute disabled
			element.removeAttribute("disabled");
		});
	} else {
		basketMainIcon.forEach((element) => {
			// Add attribute disabled
			element.setAttribute("disabled", "disabled");
		});
	}
}
/**
 * Delete basket product
 * @param {*} basketId
 * @param {*} cpid
 * @param {*} currency
 */
function deleteBasketProduct(basketId, cpid, currency) {
	var __basket = getCpyBasket(cpid);
	var __product = __basket.products.find((x) => x.BasketId == basketId);
	if (__product != null) {
		__basket.products.splice(__basket.products.indexOf(__product), 1);
		updateBaskets(__basket);
		setBasketPriceAndQty({
			currency: currency,
			cpid: cpid
		});
	}
}
/**
 * Delete all basket products
 * @param {*} cpid
 * @param {*} currency
 */
function deleteAllBasketProducts(cpid, currency) {
	var __basket = getCpyBasket(cpid);
	__basket.products = [];
	updateBaskets(__basket);
	setBasketPriceAndQty({
		currency: currency,
		cpid: cpid
	});
}

function checkProductsDeactivated(__basket) {
	var products = __basket.products;
	var deactivated = _deactivatedModel.getDeactivated(__basket.cpid);
	var removed = false;
	if (products == null || deactivated == null) {
		return;
	}
	// check if products are deactivated
	for (var i = 0; i < products.length; i++) {
		var product = products[i];
		var deactivatedItem = deactivated.deactivated.find(
			(x) =>
				x.id == product.ProductDataId &&
				(x.variant == "" || x.variant == product.Variant.variantName)
		);
		if (deactivatedItem != null) {
			// remove product
			products.splice(products.indexOf(product), 1);
			removed = true;
		}
	}
	// Updated with basket
	if (removed) {
		updateBaskets(__basket);
	}
}

module.exports = {
	setBasketPriceAndQty,
	getCpyBasket,
	deleteBasketProduct,
	updateBaskets,
	deleteAllBasketProducts,
	deleteBasket,
	checkProductsDeactivated
};
