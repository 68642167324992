import axios from "axios";

var waitTime = 5;

function SetClassicHeaders(addBearer = true, token = global.TOKEN) {
	var cors = { "Access-Control-Allow-Origin": "*" };
	var accept = { Accept: "application/json" };
	var contentType = { "Content-Type": "application/json" };
	var auth = { Authorization: "Bearer " + token };
	var appId = { "app-id": "gguqmgbqufktnwljbxsuwyvfyyiepykgqrvu" };
	var headers = {
		headers: {
			...cors,
			...accept,
			...contentType,
			...appId
		}
	};
	if (addBearer) {
		headers.headers = { ...headers.headers, ...auth };
	}

	return headers;
}

function SetGetHeaders() {
	var accept = { Accept: "*/*" };
	var auth = { Authorization: "Bearer " + global.TOKEN };

	var headers = {
		...accept,
		...auth
	};

	return headers;
}

async function GetAll(url, bearer = true, token = global.TOKEN) {
	// wait for seconds
	await new Promise((r) => setTimeout(r, waitTime));

	try {
		var header = SetClassicHeaders(bearer, token);
		var response = await axios.get(url, header);
		var data = response.data.data;
		return data;
	} catch (err) {
		err;
	}
	return null;
}
async function GetFilter(url, bearer = true, token = global.TOKEN) {
	await new Promise((r) => setTimeout(r, waitTime));
	try {
		var header = SetClassicHeaders(bearer, token);
		var response = await axios.get(url, header);
		var data = response.data;
		return data;
	} catch (err) {
		console.log(err);
		err;
	}
	return null;
}
async function GetOne(url, bearer = true, token = global.TOKEN) {
	await new Promise((r) => setTimeout(r, waitTime));
	try {
		var header = SetClassicHeaders(bearer, token);
		var response = await axios.get(url, header);
		var data = response.data;
		return data;
	} catch (err) {
		console.log(err);
		err;
	}
	return null;
}
// Todo: Gestion de la file d'attente pour envoi en ligne.
async function CreateOne(url, model, bearer = true) {
	await new Promise((r) => setTimeout(r, waitTime));
	try {
		var body = JSON.stringify(model);
		var header = SetClassicHeaders(bearer);
		var response = await axios.post(url, body, header);
		var data = response.data;
		return data;
	} catch (err) {
		err;
	}
	return null;
}

async function UpdateOne(url, model, bearer = true) {
	await new Promise((r) => setTimeout(r, waitTime));
	try {
		var body = JSON.stringify(model);
		var header = SetClassicHeaders(bearer);
		var response = await axios.put(url, body, header);
		var data = response.data;
		return data;
	} catch (err) {
		err;
	}
	return null;
}

async function DeleteOne(url, model, bearer = true) {
	await new Promise((r) => setTimeout(r, waitTime));
	try {
		var body = JSON.stringify(model);
		var header = SetClassicHeaders(bearer);
		var response = await axios.delete(url, body, header);
		var data = response.data;
		return data;
	} catch (err) {
		err;
	}
	return null;
}

function SetClassicHeadersFormControl(addBearer = true, token = global.TOKEN) {
	var cors = { "Access-Control-Allow-Origin": "*" };
	var contentType = { "Content-Type": "application/x-www-form-urlencoded" };
	var auth = { Authorization: "Bearer " + token };
	var appId = { "app-id": "gguqmgbqufktnwljbxsuwyvfyyiepykgqrvu" };
	var headers = {
		headers: {
			...cors,
			...contentType,
			...appId
		}
	};
	if (addBearer) {
		headers.headers = { ...headers.headers, ...auth };
	}

	return headers;
}

export default {
	GetAll,
	GetFilter,
	GetOne,
	CreateOne,
	UpdateOne,
	DeleteOne,
	SetGetHeaders,
	SetClassicHeaders,
	SetClassicHeadersFormControl
};
