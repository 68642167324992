/**
 * REACT
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
/**
 * Context
 */
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";
import addNotification from '@bdhamithkumara/react-push-notification';
import 'react-toastify/dist/ReactToastify.css';


/**
 * Logger
 */
const RitsReactConsole = require("rits-node-framework/debug/rits-react-console.js");
const _logger = new RitsReactConsole(global.LOG_LEVEL);
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import OrderItemsContent from "./order-items-content.js";
import OrderManagement from "./order-management.js";
import TipsComponent from "./tips-component.js";

// Import Swiper styles
import 'swiper/css';
/**
 * Services
 */
import {
	LoadDataOrders,
	LoadDataCompany
} from "../../../data/services/load-data-service.js";


/**
 * Constants
 */
const isBrowser = typeof window !== `undefined`;
//import UseAudio from "../common/shared/common-audio.js";
const _orderModel = require("../../models/orders.js");


const OrderItems = (_props) => {
	var props = _props.properties;
	const ticks = new Date().getTime();
	const componentId = "page-dashboard-" + ticks;
	const [loaded, setLoaded] = useState(false);
	const [companyData, setCompanyData] = useState(null);
	const [userEmail, setUserEmail] = useState(null);
	const [ordersPending, setOrdersPending] = useState([]);
	const [ordersReady, setOrdersReady] = useState([]);
	const [ordersDelivered, setOrdersDelivered] = useState([]);
	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();
	const [refresh, setRefresh] = useState(process.env.REACT_APP_ORDER_REFRESH);
	const minOrderStatus = 1;
	const maxOrderStatus = 3;

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	async function componentDidMount() {
		try {
			setLoadFunction();
		} catch (e) {
			onError(e);
		}
	}

	async function askUserPermission() {
		return await window.Notification.requestPermission().then(function () {
			// get element notification-permission-card
			var card = document.getElementById("notification-permission-card");
			if (card != null) {
				card.remove();
			}
		});
	}
	/**
	  * Load all datas
	  */
	async function setLoadFunction() {
		var company = global.COMPANY_DATAS;
		if (company == null) {
			company = await LoadDataCompany();
			global.COMPANY_DATAS = company;
			//console.log(data);
			props.pageName = company.companyName;
			props.base64Image = company.companyDetails.CompanyLogo;

		}
		setCompanyData(company);
		setUserEmail(company.name);
		_logger.DEBUG(
			`Loaded ${props.pageName} with id ${componentId} logged user ${userEmail}`
		);
		document.title = props.pageName;
		setRefresh(process.env.REACT_APP_ORDER_REFRESH ?? 10000);
		// wait for seconds
		setTimeout(async () => {
			setLoaded(true);
			await TickGetDatas()
			ordersRefresh();
		}, 50);

	}
	/**
	  * Handles errors and shows a toast message.
	  * @param {Error} error - The error object.
	  */
	function onError(error) {
		sendToast(
			`From Cart ${error}`,
			"error",
			t("error", { ns: props.trNamespace })
		);
	}
	/**
	 * Sends a toast message.
	 * @param {*} message
	 * @param {*} severity
	 * @param {*} title
	 */
	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	const notify = (response) => {

		addNotification(response);
		if (isBrowser) {
			var notification = new Notification(response.message)
			setTimeout(() => {
				closeNotification(notification);
			}, 500);
		}
	};

	function closeNotification(notification) {
		notification.close();
	}

	async function ordersRefresh() {
		setTimeout(async () => {
			await TickGetDatas();
		}, refresh);
	}
	function orderLogic(orderList, AllOrders, _name) {
		if (AllOrders == null || AllOrders.length == 0) {
			AllOrders = [];
		}
		var _orders = [];
		// if any item in AllOrders with name = name take it
		for (var i = 0; i < AllOrders.length; i++) {
			if (AllOrders[i].name == _name) {
				_orders = AllOrders[i].orders;
			}
		}
		return processOrders(orderList, _orders, _name);
	}
	function processOrders(data, _orders, _name) {
		var moved = false;
		try {
			var addedOrders = 0;
			var removedOrders = 0;
			if (data == null || data.length == 0) {
				return false;
			}
			if (_orders == null || _orders.length == 0) {
				addedOrders = data.length;
			} else {
				var status = 1;
				if (_name == "ready") {
					status = 2;
				} else if (_name == "delivered") {
					status = 3;
				}
				for (var i = 0; i < data.length; i++) {
					//if order is not in orders add it				
					if (!_orders.find(x => x == data[i].Id)) {
						//orders.push(data.orders[i]);
						addedOrders++;
					}
				}
				for (var j = 0; j < _orders.length; j++) {
					if (!data.find(x => x.Id == _orders[j])
						|| data.find(x => x.OrderStatus != status)
					) {
						removedOrders++;
					}
				}
			}

			if (addedOrders > 0 || removedOrders > 0) {
				if (_name == "pending") {
					// get only data wirh OrderStatus = 1
					data = data.filter((x) => x.OrderStatus == 1);
					// sort by DeliveryDate
					data = data.sort(function (a, b) {
						return new Date(a.DeliveryDate) - new Date(b.DeliveryDate);
					});

					setOrdersPending(data);
				} else if (_name == "ready") {
					data = data.filter((x) => x.OrderStatus == 2);
					data = data.sort(function (a, b) {
						return new Date(a.DeliveryDate) - new Date(b.DeliveryDate);
					});

					setOrdersReady(data);
				} else if (_name == "delivered") {
					data = data.filter((x) => x.OrderStatus == 3);
					data = data.sort(function (a, b) {
						return new Date(a.DeliveryDate) - new Date(b.DeliveryDate);
					});

					setOrdersDelivered(data);
				}
				moved = true;
			}
			if (addedOrders > 0) {
				_orderModel.setOrders(data, _name);
				var txt = t("newOrder", { ns: props.trNamespace })
				if (addedOrders > 1) {
					txt = t("newOrders", { ns: props.trNamespace });
				}
				if (_name == "pending") {
					sendToast(addedOrders + " " + txt, "info", t("info", { ns: props.trNamespace }), true);
					notify({
						title: t("info", { ns: props.trNamespace }),
						message: addedOrders + " " + txt,
						native: true, // when using native, your OS will handle theming.
						subtitle: 'This is a subtitle',
						colorTop: "#71DB46",
						duration: 5000,
						position: "top-right",
						severity: "info"
					});
				}

			} if (removedOrders > 0) {
				_orderModel.setOrders(data, _name);
			}
		} catch (e) {
			console.log(e)
			_logger.ERROR(`Error in TickGetDatas ${e}`);
		}
		return moved;
	}

	async function TickGetDatas() {
		if (companyData == null) {
			ordersRefresh();
			return;
		}
		var data = await LoadDataOrders(companyData.company.CompanyId);
		var _orders = _orderModel.getOrders();
		// Check id data has orders prop

		if (data != null && data.orders != null) {
			orderLogic(data.orders.filter((x) => x.OrderStatus == 1), _orders, "pending");
			orderLogic(data.orders.filter((x) => x.OrderStatus == 2), _orders, "ready");
			orderLogic(data.orders.filter((x) => x.OrderStatus == 3), _orders, "delivered");
			ordersRefresh();

		}
	}

	function setAccordion(status) {

		var lst = [];
		var name = ""
		var _className = "bg-primary";

		switch (status) {
			case 1:
				lst = ordersPending;
				name = t("order-status-1", { ns: props.trNamespace });
				_className = "bg-danger";
				break;
			case 2:
				lst = ordersReady;
				name = t("order-status-2", { ns: props.trNamespace });
				_className = "bg-info";
				break;
			case 3:
				lst = ordersDelivered;
				name = t("order-status-3", { ns: props.trNamespace });
				_className = "bg-primary";
				break;
			default:
				break;
		}

		return (
			<div className="card mb-4 animate slide delay-2">
				<div className="card-header bg-primary-gradient p-4">
					{setSliders(_className, name, lst, status)}
				</div>
				<div className="card-body">
					<div className="row">
						{setTips("order-status.slide", status)}
					</div>
					<div className="row" id={`ordered-list-${status}`}>
						{lst.map((order, index) => {
							return <OrderItemsContent
								key={index}
								properties={{
									pageName: props.pageName,
									trNamespace: props.trNamespace,
									order: order,
									itemChange: SetNewParent
								}}
							></OrderItemsContent>;
						})}
						<div className="m-4"></div>
					</div>
				</div>
			</div>
		)
	}
	function setBreadCumb(index) {
		var _class = ["m-2 mt-0 mb-0 txt-dark animate pop delay-1", "m-2 mt-0 mb-0 txt-dark animate pop delay-2", "m-2 mt-0 mb-0 txt-dark animate pop delay-3"];
		if (index != null) {
			_class[index] = `m-2 mt-0 mb-0 text-warning  animate slide delay-${index}`;
		}

		return (
			<nav aria-label="breadcrumb mt-0 mb-0 ">
				<ol className="breadcrumb txt-dark mt-0 mb-0">
					<li><span className={_class[0]} disabled id="bc-st-0" data-type="bc-btn">{t("order-status-1", { ns: props.trNamespace })}</span>{" > "}</li>
					<li><span className={_class[1]} disabled id="bc-st-1" data-type="bc-btn">{t("order-status-2", { ns: props.trNamespace })}</span>{" > "}</li>
					<li><span className={_class[2]} disabled id="bc-st-2" data-type="bc-btn">{t("order-status-3", { ns: props.trNamespace })}</span></li>
				</ol>
			</nav>
		);

	}
	function setSelectedIndex(index) {
		// get all data-type="bc-btn"
		var btns = document.querySelectorAll('[data-type="bc-btn"]');
		btns.forEach((element) => {
			element.classList.remove("txt-warning");
			element.classList.add("txt-dark");
			if (element.id == "bc-st-" + index) {
				element.classList.remove("txt-dark");
				element.classList.add("text-warning");
			}
		});

	}

	function SetNewParent(_componentId, _status, order) {
		var _ordersPending = ordersPending;
		var _ordersReady = ordersReady;
		var _ordersDelivered = ordersDelivered;
		// remove order from all list 
		_ordersPending = _ordersPending.filter((x) => x.Id != order.Id);
		_ordersReady = _ordersReady.filter((x) => x.Id != order.Id);
		_ordersDelivered = _ordersDelivered.filter((x) => x.Id != order.Id);
		if (_status == 1) {
			_ordersPending.push(order);
		}
		if (_status == 2) {
			_ordersReady.push(order);
		}
		if (_status == 3) {
			_ordersDelivered.push(order);
		}
		setOrdersPending(_ordersPending);
		setOrdersReady(_ordersReady);
		setOrdersDelivered(_ordersDelivered);
	}

	function setSliders(_className, name, lst, orderStatus) {

		var previous = null;
		var next = null;
		if (orderStatus > minOrderStatus) {
			previous = (
				<p className="float-end p-0 m-0 h2-mob">
					<span>
						<i className="fas text-white fa-caret-left animate-loop animate-right"></i>
					</span>
				</p>
			)
		}
		if (orderStatus < maxOrderStatus) {
			next = (<p className="float-start p-0 m-0  h2-mob">
				<span>
					<i className="fas text-white fa-caret-right animate-loop animate-left"></i>
				</span>
			</p>)
		}


		return (
			<div className="row p-0 m-0">
				<div className="col-sm-12 p-0 m-0">
					<p className=" p-0 m-0 h1-mob">
						<span className={`badge ${_className} h1-mob`}>
							<span >
								{t("orders.title", { ns: props.trNamespace })}{" "}{name}
							</span>
							<span>&nbsp;</span>
							<span>({lst.length})</span>
						</span>
					</p>
				</div>
				<div className="col-sm-12 p-0 m-0">
					<div className="container-inline-spaced  p-0 m-0">
						<div>
							{previous}
						</div>
						<div>
							{next}
						</div>
					</div>
				</div>
			</div >
		)
	}
	function setTimeSlots() {

		return (
			<OrderManagement
				properties={{
					pageName: props.pageName,
					trNamespace: props.trNamespace
				}}
			>
			</OrderManagement >
		)


	}
	function setTips(name, id) {
		return (
			<TipsComponent
				properties={{
					pageName: props.pageName,
					trNamespace: "orders",
					name: name,
					id: id,
					color: "info"
				}}
			>

			</TipsComponent >)
	}

	function createNotificationPopup() {
		var notif = null;
		if (isBrowser) {
			if (("Notification" in window) && Notification.permission != "granted" && Notification.permission !== "denied") {
				notif = (
					<div className="card" id="notification-permission-card">
						<div className="card-header bg-warning">
							<h5 className="modal-title" id="notificationModalLabel">{t("common:notification-permission.title")}</h5>
						</div>
						<div className="card-body">
							<p>{t("common:notification-permission.message")}</p>
						</div>
						<div className="card-footer bg-warning-light">
							<button type="button" onClick={async () => {
								await askUserPermission();
							}} className="btn btn-warning" data-bs-dismiss="modal">{t("common:accept")}</button>
						</div>
					</div>
				)
			}
		}

		return notif;
	}

	return loaded ? (
		<>
			{createNotificationPopup()}
			{setTimeSlots()}
			{setTips("order-status.navigate", 99)}
			{setBreadCumb(0)}
			<Swiper
				spaceBetween={0}
				slidesPerView={1}
				onSlideChange={(slide) => {
					setSelectedIndex(slide.activeIndex);

				}}
			>
				<SwiperSlide>{setAccordion(1)}</SwiperSlide>
				<SwiperSlide>{setAccordion(2)}</SwiperSlide>
				<SwiperSlide>{setAccordion(3)}</SwiperSlide>
			</Swiper>

		</>

	) : null;
};

OrderItems.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	itemClick: PropTypes.string
};

export default OrderItems;
