/**
 * ! Change PrintComponent by your component name
 */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useToastContext } from "../../../context/toast-context.js";
import { initTranslations } from "../../../i18n/i18n.js";

import OrderService from "../../../data/services/application/order.js";

const PrintComponent = (_props) => {
	var props = _props.properties;
	const componentId = "PrintComponent-" + Math.random().toString(36).substring(7);
	const [loaded, setLoadedState] = useState(false);

	const { t } = initTranslations();
	const { handleShowToast } = useToastContext();

	useEffect(() => {
		componentDidMount();
	}, [loaded]);

	function componentDidMount() {
		try {
			setLoadFunction(true);
		} catch (e) {
			onError(e);
		}
	}
	// Execute after load until all is ok.
	function setLoadFunction(data) {
		try {
			if (data != null) {
				setLoadedState(data);
			}
		} catch {
			setTimeout(() => {
				setLoadFunction(data);
			}, 50);
		}
	}
	/**
	 * Handles errors and shows a toast message.
	 * @param {Error} error - The error object.
	 */
	function onError(error) {
		sendToast(`${error}`, "error", t("error", { ns: props.trNamespace }));
	}

	function sendToast(message, severity, title, playSound = false) {
		handleShowToast(severity, title, `${message}`, playSound);
	}

	async function PrintOrder() {
		var Id = props.order.Id;
		var PrintIt = 1;
		var item = { PrintIt: PrintIt, Id: Id };
		await OrderService.SetPrint(item);
		sendToast(t("orders:print-orders.message"), "success", t("orders:print-orders.title"));
	}

	async function PrintInvoice() {
		var Id = props.order.Id;
		var PrintInvoice = 1;
		var item = { PrintInvoice: PrintInvoice, Id: Id, PrintIt: null };
		await OrderService.SetPrint(item);
		sendToast(t("orders:print-orders.message"), "success", t("orders:print-orders.title"));
	}

	return loaded ? (
		<div className="container-fluid ">
			<div className="container-inline-right m-1" key={componentId}>
				<button className="btn btn-warning btn-circle btn-small h-100 float-start m-1 mt-0 mb-0" type="button" aria-expanded="true" onClick={PrintOrder}>
					<i className="fa fa-print toast-mob" style={{ marginRight: "10px" }}></i>
					<span className="toast-mob">{t("orders:kitchen-orders")}</span>
				</button>

				<button className="btn btn-info btn-circle btn-small h-100 float-start m-1 mt-0 mb-0" type="button" aria-expanded="true" onClick={PrintInvoice}>
					<i className="fa fa-receipt toast-mob" style={{ marginRight: "10px" }}></i>
					<span className="toast-mob">{t("orders:invoice-orders")}</span>
				</button>
			</div>
		</div>
	) : null;
};

PrintComponent.propTypes = {
	pageName: PropTypes.string,
	trNamespace: PropTypes.string,
	order: PropTypes.object
};

export default PrintComponent;
