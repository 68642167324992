const lsName = "mo_company";
/**
 * Set company datas in local storage
 * @param {*} param0
 */
function setCompanyDatas({
	companyId,
	companyName,
	companyLogo,
	currency,
	companyParams
}) {
	var company = {
		companyId: companyId,
		companyName: companyName,
		companyLogo: companyLogo,
		currency: currency,
		ShowProductImage: companyParams.ShowProductImage
	};
	localStorage.setItem(lsName, JSON.stringify(company));
}
/**
 * Get company datas from local storage
 * @returns
 */
function getCompanyDatas() {
	var company = null; // JSON.parse(localStorage.getItem(lsName));
	var item = localStorage.getItem(lsName);
	if (item == null) {
		return null
	}
	var stored = JSON.parse(item);
	company = stored;
	company.companyId = parseInt(company.companyId);
	return company;
}

module.exports = { setCompanyDatas, getCompanyDatas };
